import React, { useContext, useEffect, useState } from 'react';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, Skeleton, Typography } from 'sunwise-ui';

import {
    PDF_X_MARGIN,
    TEMPLATE_PAGE_HEIGHT,
    TEMPLATE_PAGE_WIDTH,
} from 'common/constants/templates';
import { LoadingContext } from 'common/utils/contexts';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const WrapperPage = styled(Box)`
    display: flex;
    justify-content: center;
    box-shadow: 20px 2px 17px 0 rgba(120, 112, 178, 0.08);
    margin-bottom: 24px;
    position: relative;
`;

const PdfViewer = ({ document, format = 'pdf' }) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [numPages, setNumPages] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [scalePage, setScalePage] = useState(1);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isLoading) loadingContext.openLoading(t('Loading').concat('...'));
        else loadingContext.closeLoading();
    }, [isLoading]);

    const handleCloseLoading = () => setIsLoading(false);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setIsLoading(false);
    };

    const onPageLoad = (page) => {
        const scale = (window.innerWidth - PDF_X_MARGIN) / page.originalWidth;
        if (scale !== scalePage) {
            setScalePage(scale);
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            {format === 'pdf' ? (
                <Document
                    error={
                        <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            gap={2}
                            py={3}
                        >
                            <PictureAsPdfIcon />
                            <Typography fontWeight="bold" variant="caption">
                                {t('Failed to load PDF file')}
                            </Typography>
                        </Box>
                    }
                    file={document}
                    loading={() => (
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                        >
                            <Skeleton
                                variant="rectangular"
                                width={TEMPLATE_PAGE_WIDTH}
                                height={TEMPLATE_PAGE_HEIGHT}
                            />
                        </Box>
                    )}
                    onLoadError={handleCloseLoading}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onSourceSuccess={handleCloseLoading}
                >
                    {Array.from(new Array(numPages)).map((el, index) => (
                        <Box sx={{ width: '100%' }} key={`page-${index}`}>
                            <WrapperPage
                                sx={{ display: { xs: 'none', lg: 'block' } }}
                            >
                                <Page
                                    pageNumber={index + 1}
                                    width={TEMPLATE_PAGE_WIDTH}
                                />
                            </WrapperPage>
                            <WrapperPage
                                sx={{ display: { xs: 'block', lg: 'none' } }}
                            >
                                <Page
                                    pageNumber={index + 1}
                                    onLoadSuccess={onPageLoad}
                                    scale={scalePage}
                                />
                            </WrapperPage>
                        </Box>
                    ))}
                </Document>
            ) : (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <WrapperPage
                        sx={{
                            display: { xs: 'none', lg: 'block' },
                            width: TEMPLATE_PAGE_WIDTH,
                        }}
                    >
                        <img src={document.url} width={TEMPLATE_PAGE_WIDTH} />
                    </WrapperPage>
                    <WrapperPage
                        sx={{ display: { xs: 'block', lg: 'none' }, px: 2 }}
                    >
                        <img src={document.url} width="100%" />
                    </WrapperPage>
                </Box>
            )}
        </Box>
    );
};

PdfViewer.propTypes = {
    document: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    format: PropTypes.string,
};

export default PdfViewer;
