export default (renderName, url, callback) => () => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${renderName}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    setTimeout(() => link && URL.revokeObjectURL(link.href), 7000);
    if (callback) return callback();
};
