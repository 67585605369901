import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Typography, Tooltip } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import { formatDate, parseDate } from 'common/utils/dates';

const getMaxItems = ({ breakpoint } = {}) => {
    if (breakpoint === 'xs') return 2;
    if (breakpoint === 'sm') return 3;
    return 4;
};

const CollapsedBreadcrumbs = ({ interactiveProposalData }) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const createdAt = get(
        interactiveProposalData,
        'proposal_creation_date',
        ''
    );
    const projectName = get(interactiveProposalData, 'project_name', '');
    const clientFullName = get(interactiveProposalData, 'client_full_name', '');
    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            maxItems={getMaxItems({ breakpoint })}
        >
            <Tooltip title={t('Client')}>
                <Typography variant="body2">{clientFullName}</Typography>
            </Tooltip>
            <Tooltip title={t('Project')}>
                <Typography variant="body2">{projectName}</Typography>
            </Tooltip>
            <Tooltip title={t('Date')}>
                <Typography variant="body2">
                    {createdAt
                        ? formatDate(
                              parseDate(createdAt, 'yyyy-MM-dd'),
                              'dd/MM/yyyy'
                          )
                        : null}
                </Typography>
            </Tooltip>
        </Breadcrumbs>
    );
};

CollapsedBreadcrumbs.propTypes = {
    interactiveProposalData: PropTypes.object,
};

export default CollapsedBreadcrumbs;
