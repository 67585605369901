import axios from 'axios';

import {
    getIanaTimezoneKey,
    getRuntimeLanguage,
} from '../utils/helpers/multiregion';

const API = axios.create({
    baseURL: process.env.REACT_APP_RENDER_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

API.interceptors.request.use((req) => {
    req.headers['Accept-Language'] = getRuntimeLanguage();
    req.headers['X-Timezone-IANA'] = getIanaTimezoneKey();
    req.headers['X-Branch-Office'] = 'all';

    return req;
});

API.interceptors.response.use(
    (res) => res,
    (error) => Promise.reject(error)
);

export default API;
