import set from 'lodash/set';

import getValidationTexts from 'common/utils/getValidationTexts';

export default (prepareSchema) => (values, props) => {
    try {
        const validationTexts = getValidationTexts();
        const schema = prepareSchema(validationTexts, props);
        schema.validateSync(values, { abortEarly: false });
        return {};
    } catch (errors) {
        return errors.inner.reduce((acc, current) => {
            let tempObject = { ...acc };
            set(tempObject, current.path, current.message);
            return tempObject;
        }, {});
    }
};
