import React from 'react';

import DescriptionIcon from '@mui/icons-material/Description';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
} from 'sunwise-ui';

import { DATASHEET_PDF_TYPE } from '../constants';
import {
    getDatasheetLabel,
    getDatasheetsByType,
    getDatasheetTypes,
} from '../helpers';

import SideMenuCard from './SideMenuCard';

const DataSheetsCard = ({
    datasheets,
    handleClickDatasheetItem,
    isDisabled,
    selectedDocumentId,
}) => {
    const { t } = useTranslation();

    if (datasheets?.length === 0) return <></>;

    const datasheetTypes = getDatasheetTypes(datasheets);

    return (
        <SideMenuCard
            icon={<DescriptionIcon sx={{ color: 'primary.main' }} />}
            title={t('Data sheet', { count: 2 })}
        >
            {datasheetTypes.map((type) => (
                <List key={`datasheet-type-${type}`} disablePadding>
                    <Typography
                        component="span"
                        variant="body2"
                        fontWeight="bold"
                        sx={{ p: '8px 16px' }}
                    >
                        {getDatasheetLabel(type)}
                    </Typography>
                    {getDatasheetsByType(datasheets, type).map((datasheet) => (
                        <ListItem
                            disabled={
                                isDisabled ||
                                datasheet.id === selectedDocumentId
                            }
                            disablePadding
                            key={`datasheet-${datasheet.id}`}
                            selected={datasheet.id === selectedDocumentId}
                            sx={{
                                borderRadius: '8px',
                                mb: '5px',
                                '&.Mui-selected': {
                                    backgroundColor: 'rgba(31, 60, 83, 0.13)',
                                },
                            }}
                        >
                            <ListItemButton
                                onClick={() =>
                                    handleClickDatasheetItem({
                                        archive: datasheet.archive,
                                        format: 'pdf',
                                        id: datasheet.id,
                                        name: datasheet.code,
                                        type: DATASHEET_PDF_TYPE,
                                    })
                                }
                                sx={{ borderRadius: '8px' }}
                            >
                                <ListItemText
                                    primary={datasheet.name}
                                    sx={{
                                        '.MuiListItemText-primary': {
                                            fontSize: '14px',
                                        },
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            ))}
        </SideMenuCard>
    );
};

DataSheetsCard.propTypes = {
    datasheets: PropTypes.array,
    handleClickDatasheetItem: PropTypes.func,
    isDisabled: PropTypes.bool,
    selectedDocumentId: PropTypes.string,
};

export default DataSheetsCard;
