import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { addDefaultSrc, handleFileURL } from 'common/utils/helpers';

import WrapperLogo from './WrapperLogo';
import WrapperLogotype from './WrapperLogotype';

const CompanyLogo = ({ branding, companyLogo }) => {
    if (isEmpty(branding)) {
        return (
            <WrapperLogo>
                <img
                    onError={(e) => addDefaultSrc(e, 200)}
                    src={handleFileURL(
                        companyLogo,
                        process.env.REACT_APP_S3_MEDIA_PATH
                    )}
                />
            </WrapperLogo>
        );
    }

    return (
        <WrapperLogotype>
            <img
                onError={(e) => addDefaultSrc(e, 200)}
                src={handleFileURL(
                    branding?.logotype_image,
                    process.env.REACT_APP_S3_MEDIA_PATH
                )}
            />
        </WrapperLogotype>
    );
};

CompanyLogo.propTypes = {
    branding: PropTypes.object,
    companyLogo: PropTypes.string,
};

export default CompanyLogo;
