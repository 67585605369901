import { authorization } from 'common/api/v1/burocredito';

import { AUTH, AUTH_FAILURE, AUTH_SUCCESS } from '../actionTypes';
import { BUREAU_STEPS } from '../constants';

import setCurrentStep from './setCurrentStep';

export default (data, token) => (dispatch) => {
    dispatch({ type: AUTH });

    const values = { nip: data.nip };

    authorization(values, token)
        .then((response) => {
            dispatch({ type: AUTH_SUCCESS, payload: response.data });
            dispatch(setCurrentStep(BUREAU_STEPS.COMPLETE));
        })
        .catch((error) =>
            dispatch({
                type: AUTH_FAILURE,
                payload: error?.response?.data?.errors,
            })
        );
};
