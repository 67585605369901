import i18next from 'i18next';
import { isEmpty, isNull } from 'lodash';
import { types } from 'sunwise-template-core';

import { URL_TYPES } from 'common/constants';
import { formatDate, parseDate, subDate } from 'common/utils/dates';
import { getFileExtensionByPath } from 'common/utils/helpers';

import {
    DATASHEET_PDF_TYPE,
    ELECTRIC_BILL_TYPE,
    FINANCING_TYPE,
    PDF_LOW_QUALITY,
    PDF_MEDIUM_QUALITY,
    PDF_REAL_TEXT,
} from './constants';

const getElectricBillImage = async (archiveUrl) => {
    try {
        const response = await fetch(archiveUrl);
        const blob = await response.blob();
        return URL.createObjectURL(blob);
    } catch (error) {
        return error;
    }
};

export const getElectricBillPerMonths = ({ isBimonthly = false, lines }) => {
    if (lines && !isEmpty(lines)) {
        const filteredKwh = lines.filter((line) => line.url);

        const daysToSubstract = isBimonthly ? 30 : 15;

        return filteredKwh.map((item) => {
            const label = formatDate(
                subDate(parseDate(item.final_date, 'dd/MM/yyyy'), {
                    days: Math.floor(daysToSubstract),
                }),
                'MMM yy'
            );

            return {
                url: item.url,
                id: Math.random(),
                label: label,
                urlType: item.url_type,
            };
        });
    }
    return [];
};

export const getDatasheetLabel = (key) => {
    const labels = {
        accesories: i18next.t('Accessory', { count: 2 }),
        additionals: i18next.t('Additional', { count: 2 }),
        batteries: i18next.t('Energy backup'),
        inverters: i18next.t('Inverter', { count: 2 }),
        panels: i18next.t('Panel', { count: 2 }),
        structures: i18next.t('Structure', { count: 2 }),
        workforces: i18next.t('Workforce and electrical equipment'),
    };
    return labels[key];
};

export const getDatasheetsByType = (datasheets, type) => {
    const data = datasheets.map((item) => ({ ...item, type: item.type?.key }));
    return data.filter((datasheet) => datasheet.type === type);
};

export const getDatasheetTypes = (datasheets) => {
    const data = [...datasheets].map((item) => item.type?.key);
    return [...new Set(data)];
};

export const getDownloadOptions = (selectedDocument) => {
    const { archive, format, type, urlType } = selectedDocument;
    let options = [];
    switch (type) {
        case DATASHEET_PDF_TYPE:
            options = [
                {
                    label: `PDF - ${i18next.t('File')}`,
                    value: PDF_MEDIUM_QUALITY,
                },
            ];
            break;
        case ELECTRIC_BILL_TYPE:
            if (urlType === URL_TYPES.LISA && format === 'png')
                options = [
                    {
                        label: `${i18next
                            .t('Image')
                            .toUpperCase()} - ${i18next.t('File')}`,
                        value: PDF_MEDIUM_QUALITY,
                    },
                ];
            else
                options = [
                    {
                        label: `PDF - ${i18next.t('File')}`,
                        value: PDF_MEDIUM_QUALITY,
                    },
                ];
            break;
        case FINANCING_TYPE:
            if (archive)
                options = [
                    {
                        label: `PDF - ${i18next.t('File')}`,
                        value: PDF_MEDIUM_QUALITY,
                    },
                ];
            else
                options = [
                    { label: 'PDF', value: PDF_REAL_TEXT },
                    {
                        label: `PDF - ${i18next.t('Low quality')}`,
                        value: PDF_LOW_QUALITY,
                    },
                    {
                        label: `PDF - ${i18next.t('Medium quality')}`,
                        value: PDF_MEDIUM_QUALITY,
                    },
                ];
            break;
        default:
            options = [
                { label: 'PDF', value: PDF_REAL_TEXT },
                {
                    label: `PDF - ${i18next.t('Low quality')}`,
                    value: PDF_LOW_QUALITY,
                },
                {
                    label: `PDF - ${i18next.t('Medium quality')}`,
                    value: PDF_MEDIUM_QUALITY,
                },
            ];
            break;
    }
    return [
        { disabled: true, label: 'Selecciona formato', value: '' },
        ...options,
    ];
};

export const getFileNameFromURL = (archive) => {
    if (!isNull(archive))
        return archive.substring(archive.lastIndexOf('/') + 1);
    return '';
};

export const getFormattedDate = (date) => {
    if (date)
        return formatDate(parseDate(date, 'yyyy/MM/dd HH:mm:ss'), 'dd/MM/yyyy');
    return null;
};

export const getIsLoadingContextMessage = ({
    isFetchingBranding,
    isFetchingElectricBills,
    isFetchingFinancingTemplate,
    isFetchingInteractiveProposal,
    isFetchingInteractiveProposalContent,
    isFetchingInteractiveProposalSignature,
    isFetchingSmartDocumentContent,
    loadingContext,
}) => {
    if (isEmpty(loadingContext)) return;
    if (
        isFetchingBranding ||
        isFetchingElectricBills ||
        isFetchingFinancingTemplate ||
        isFetchingInteractiveProposal ||
        isFetchingInteractiveProposalContent ||
        isFetchingInteractiveProposalSignature ||
        isFetchingSmartDocumentContent
    )
        loadingContext.openLoading(i18next.t('Loading').concat('...'));
    else loadingContext.closeLoading();
};

export const getIsUpdatingContextMessage = ({
    isApprovingInteractiveProposal,
    isDeletingInteractiveProposalSignature,
    isSavingInteractiveProposalSignature,
    loadingContext,
}) => {
    if (isEmpty(loadingContext)) return;
    if (
        isApprovingInteractiveProposal ||
        isDeletingInteractiveProposalSignature ||
        isSavingInteractiveProposalSignature
    )
        loadingContext.openLoading(i18next.t('Loading').concat('...'));
    else loadingContext.closeLoading();
};

export const handleClickDocumentItemBuild = (
    document,
    fetchSmartDocumentContent,
    fetchFinancingTemplate,
    initTemplate,
    resetTemplate,
    selectedDocument,
    setDownloadFormat,
    setSelectedDocument,
    swipeableDrawerRef,
    templateContent
) => {
    const { id, type } = document;
    if (id !== selectedDocument.id) {
        resetTemplate();
        setSelectedDocument(document);
        setDownloadFormat(PDF_MEDIUM_QUALITY);
        switch (type) {
            case FINANCING_TYPE:
                fetchFinancingTemplate(id, initTemplate);
                break;
            case types.SMART_DOCUMENTS_TYPE:
                fetchSmartDocumentContent(id, initTemplate);
                break;
            default:
                initTemplate(JSON.parse(templateContent), id);
                break;
        }
    }
    handleCloseDrawer(swipeableDrawerRef);
};

export const handleClickDownloadBuild = (
    downloadDatasheet,
    downloadElectricBill,
    downloadFile,
    loadingContext,
    renderPdfFile,
    selectedDocument,
    downloadFormat
) => {
    const { archive, fileName, id, name, type, urlType } = selectedDocument;
    switch (type) {
        case DATASHEET_PDF_TYPE:
            return downloadDatasheet(
                getFileNameFromURL(selectedDocument.archive),
                (text) => loadingContext.openLoading(text),
                () => {
                    loadingContext.closeLoading();
                }
            );
        case ELECTRIC_BILL_TYPE:
            return downloadElectricBill(
                archive,
                fileName,
                loadingContext,
                name,
                urlType
            );
        case FINANCING_TYPE:
            if (archive) return downloadFile(name, archive, 'pdf');
            return renderPdfFile(
                id,
                name,
                () =>
                    loadingContext.openLoading(
                        i18next.t('Downloading PDF').concat('...')
                    ),
                () => loadingContext.closeLoading(),
                type,
                downloadFormat
            );
        case types.ONE_PROPOSAL_TYPE: {
            return renderPdfFile(
                id,
                name,
                () =>
                    loadingContext.openLoading(
                        i18next.t('Downloading PDF').concat('...')
                    ),
                () => loadingContext.closeLoading(),
                type,
                downloadFormat
            );
        }
        case types.SMART_DOCUMENTS_TYPE:
            return renderPdfFile(
                id,
                name,
                () =>
                    loadingContext.openLoading(
                        i18next.t('Downloading PDF').concat('...')
                    ),
                () => loadingContext.closeLoading(),
                type,
                downloadFormat
            );
        default:
            return false;
    }
};

export const handleClickElectricBillBuild = (
    document,
    fetchElectricBill,
    loadingContext,
    setDownloadFormat,
    setSelectedDocument,
    swipeableDrawerRef
) => {
    if (document.urlType === URL_TYPES.LISA) {
        loadingContext.openLoading('Obteniendo archivo...');
        fetchElectricBill(document?.fileName, async (data) => {
            let archive = data.url;
            const format = getFileExtensionByPath(data.url);
            if (format === 'png')
                archive = await getElectricBillImage(data.url);
            setSelectedDocument({
                ...document,
                archive,
                format,
            });
            loadingContext.closeLoading();
        });
    }
    if (document.urlType === URL_TYPES.SUNWISE) setSelectedDocument(document);
    setDownloadFormat(PDF_MEDIUM_QUALITY);
    handleCloseDrawer(swipeableDrawerRef);
};

export const handleCloseDrawer = (swipeableDrawerRef) => {
    if (swipeableDrawerRef.current) {
        const { handleOpen } = swipeableDrawerRef.current;
        handleOpen(false);
    }
};
