import fetchInteractiveProposal from './fetchInteractiveProposal';
import fetchInteractiveProposalContent from './fetchInteractiveProposalContent';
import saveCounterVisits from './saveCounterVisits';
export default (offerId, countVisit = true) =>
    (dispatch) => {
        Promise.all([
            dispatch(fetchInteractiveProposal(offerId)),
            dispatch(fetchInteractiveProposalContent(offerId)),
        ])
            .then(() => {
                if (!countVisit) return;
                dispatch(saveCounterVisits(offerId));
            })
            .catch(() => {});
    };
