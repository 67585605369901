import React from 'react';

import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

import SignatureImage from 'common/components/SignatureImage';
import { formatDate, parseDate } from 'common/utils/dates';

const CustomerSignature = ({
    hasSignature,
    signatureArchive,
    signatureUpdated,
}) => {
    const { t } = useTranslation();

    if (!hasSignature || isNull(signatureArchive)) return null;

    return (
        <Box>
            <Grid container justifyContent="center" mt={2}>
                <Grid item xs={18}>
                    <SignatureImage srcSignature={signatureArchive} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={1} spacing={0}>
                <Grid item xs={18} textAlign="center">
                    <Typography variant="body2">
                        {t('Signed on')}
                        <Typography variant="span" pl={1}>
                            {signatureUpdated
                                ? formatDate(
                                      parseDate(
                                          signatureUpdated,
                                          'yyyy/MM/dd HH:mm:ss'
                                      ),
                                      'dd/MM/yyyy'
                                  )
                                : null}
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

CustomerSignature.propTypes = {
    hasSignature: PropTypes.bool,
    signatureArchive: PropTypes.string,
    signatureUpdated: PropTypes.string,
};

export default CustomerSignature;
