import React, { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {
    editionLevels,
    TemplateView,
    withTemplateCore,
} from 'sunwise-template-core';
import { Box, Skeleton } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import { getCurrentLanguage } from 'common/utils/helpers/multiregion';

import PageWrapper from './PageWrapper';

const TemplateSection = ({
    initTemplate,
    isInitializing,
    isLoading,
    proposalId,
    resetTemplate,
    templateData,
}) => {
    const breakpoint = useBreakpoint();

    useEffect(() => {
        resetTemplate();
        if (!isEmpty(templateData) && proposalId) {
            initTemplate(templateData, proposalId);
        }
    }, [templateData, proposalId]);

    if (isLoading || isInitializing) {
        return (
            <Box mt={2} px={2}>
                <Skeleton variant="rectangular" height={450} />
            </Box>
        );
    }

    return (
        <PageWrapper sx={{ height: { xs: 'auto', lg: '700px' } }}>
            <TemplateView
                editionLevel={editionLevels.NO_EDITION_MODE}
                horizontalScrollMode={['xs', 'sm', 'md'].includes(breakpoint)}
                isClientView
            />
        </PageWrapper>
    );
};

TemplateSection.propTypes = {
    initTemplate: PropTypes.func,
    isInitializing: PropTypes.bool,
    isLoading: PropTypes.bool,
    proposalId: PropTypes.string,
    resetTemplate: PropTypes.func,
    templateData: PropTypes.object,
};

export default withTemplateCore(() => ({
    application: 'sunpal',
    baseUrl: process.env.REACT_APP_API_URL,
    editionMode: editionLevels.NO_EDITION_MODE,
    froalaLicenseKey: process.env.REACT_APP_FROALA_LICENSE,
    googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    language: getCurrentLanguage(),
}))(TemplateSection);
