import styled from 'styled-components';
import { Grid } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

export default styled(Grid)`
    padding: 16px;
    overflow-x: hidden;
    overflow-y: scroll;
    box-shadow: 0 2px 12px 0 rgba(129, 158, 200, 0.06);
    ${scroll.custom}
`;
