import i18next from 'i18next';

import { downloadDatasheet } from 'common/api/datasheets';
import showToast from 'common/utils/showToast';

import { DOWNLOAD_DATASHEET, DOWNLOAD_DATASHEET_FAILURE } from '../actionTypes';

export default (datasheetName, openLoading, closeLoading) => (dispatch) => {
    if (!datasheetName) {
        showToast({
            type: 'danger',
            body: i18next.t('The file url is corrupted'),
        });
        return false;
    }

    dispatch({ type: DOWNLOAD_DATASHEET });
    openLoading(i18next.t('Getting file').concat('...'));

    downloadDatasheet(datasheetName)
        .then((response) => {
            const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' })
            );
            const link = document.createElement('a');
            link.href = `${url}#${datasheetName}`;
            link.setAttribute('download', datasheetName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            closeLoading();
            showToast({
                body: i18next.t('It was created successfully'),
            });
        })
        .catch((error) => {
            dispatch({
                type: DOWNLOAD_DATASHEET_FAILURE,
                payload: error.response.data.errors,
            });
            closeLoading();
            showToast({
                type: 'danger',
                body: i18next.t('An error occurred while downloading the file'),
            });
        });
};
