import * as React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionDetails,
    Box,
    Divider,
    Typography,
} from 'sunwise-ui';

import StyledAccordionSummary from './StyledAccordionSummary';

const AccordionSection = ({
    children,
    customTitle,
    defaultExpanded,
    isHidden,
    svgIcon,
    title,
    variantTitle,
}) => {
    if (isHidden) return null;

    return (
        <Accordion defaultExpanded={defaultExpanded}>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                {svgIcon}
                {title && (
                    <Typography
                        variant={variantTitle}
                        ml={1}
                        sx={{ fontWeight: 'bold' }}
                    >
                        {title}
                    </Typography>
                )}
                {customTitle && <>{customTitle}</>}
            </StyledAccordionSummary>
            <Divider />
            <AccordionDetails sx={{ p: 0 }}>
                <Box p={2}>{children}</Box>
            </AccordionDetails>
        </Accordion>
    );
};

AccordionSection.defaultProps = {
    defaultExpanded: false,
    isHidden: false,
    variantTitle: 'body2',
};

AccordionSection.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
    ]),
    customTitle: PropTypes.object,
    defaultExpanded: PropTypes.bool,
    isHidden: PropTypes.bool,
    isLoading: PropTypes.bool,
    svgIcon: PropTypes.object,
    title: PropTypes.string,
    variantTitle: PropTypes.string,
};

export default AccordionSection;
