import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme, styled } from '@mui/material/styles';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Grid, Skeleton, Typography } from 'sunwise-ui';

import { media } from 'common/utils/mixins';

import ApprovalData from './ApprovalData';
import ChipProposalStatus from './ChipProposalStatus';
import CollapsedBreadcrumbs from './CollapsedBreadcrumbs';
import CompanyLogo from './CompanyLogo';

const Separator = styled(Box)`
    width: 4px;
    height: 40px;
    mix-blend-mode: normal;
    border-radius: 2px;
    ${media.md`
        height: 60px;
    `}
`;

const ProposalResume = ({
    branding,
    handleClickOpenApprovalModal,
    hasApproved,
    interactiveProposalData,
    interactiveProposalSignatureData,
    isLoading,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    if (isEmpty(interactiveProposalData)) {
        return null;
    }

    const proposalName = get(interactiveProposalData, 'proposal_name', '');

    return (
        <Box sx={{ backgroundColor: isDarkMode ? '#000' : '#fff' }}>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                sx={{ py: { xs: 2, md: 2 } }}
            >
                <Grid
                    item
                    xs={3}
                    md={2}
                    sx={{
                        display: { md: 'flex', xs: 'none' },
                        justifyContent: 'end',
                        alignItems: 'center',
                    }}
                >
                    <CompanyLogo
                        branding={branding}
                        companyLogo={get(
                            interactiveProposalData,
                            'compay_logo_url',
                            null
                        )}
                    />
                </Grid>
                <Grid
                    item
                    xs={17}
                    md={15}
                    sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}
                >
                    <Separator sx={{ backgroundColor: 'primary.main' }} />
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{ width: '100%' }}
                    >
                        <Grid container>
                            <Grid
                                item
                                xs={hasApproved ? 11 : 18}
                                lg={hasApproved ? 15 : 18}
                            >
                                {isLoading ? (
                                    <Skeleton
                                        variant="text"
                                        sx={{ fontSize: '1rem' }}
                                    />
                                ) : (
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontSize: {
                                                xs: '1rem',
                                                md: '1.5rem',
                                            },
                                        }}
                                    >
                                        {proposalName}
                                    </Typography>
                                )}
                            </Grid>
                            {hasApproved && (
                                <Grid
                                    item
                                    xs={6}
                                    lg={3}
                                    textAlign="center"
                                    sx={{
                                        display: { md: 'block', xs: 'none' },
                                    }}
                                >
                                    <ChipProposalStatus
                                        icon={
                                            <CheckCircleIcon
                                                sx={{
                                                    color: '#3caf5f !important',
                                                }}
                                            />
                                        }
                                        label={hasApproved ? t('Approved') : ''}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid container>
                            <Grid item xs={18}>
                                <CollapsedBreadcrumbs
                                    interactiveProposalData={
                                        interactiveProposalData
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ mb: 0 }} />
            <Box
                px={hasApproved ? 0 : 2}
                py={hasApproved ? 0 : 1}
                sx={{ display: { md: 'none', xs: 'block' } }}
            >
                {hasApproved ? (
                    <ApprovalData
                        approveEmail={get(
                            interactiveProposalSignatureData,
                            'email',
                            ''
                        )}
                        hasApproved={hasApproved}
                        onClick={() => handleClickOpenApprovalModal()}
                        updatedAt={get(
                            interactiveProposalSignatureData,
                            'updated',
                            ''
                        )}
                    />
                ) : (
                    <Button
                        fullWidth
                        onClick={() => handleClickOpenApprovalModal()}
                        size="small"
                        variant="outlined"
                    >
                        {t('Approve')}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

ProposalResume.propTypes = {
    branding: PropTypes.object,
    handleClickOpenApprovalModal: PropTypes.func,
    hasApproved: PropTypes.bool,
    interactiveProposalData: PropTypes.object,
    interactiveProposalSignatureData: PropTypes.object,
    isLoading: PropTypes.bool,
};

export default ProposalResume;
