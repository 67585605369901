import React from 'react';

import PropTypes from 'prop-types';
import { editionLevels, TemplateView } from 'sunwise-template-core';
import { Box, Skeleton } from 'sunwise-ui';

import {
    TEMPLATE_PAGE_HEIGHT,
    TEMPLATE_PAGE_WIDTH,
} from 'common/constants/templates';

const TemplateSection = ({ isLoading }) => {
    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" mt={2} px={2}>
                <Skeleton
                    height={TEMPLATE_PAGE_HEIGHT}
                    variant="rectangular"
                    width={TEMPLATE_PAGE_WIDTH}
                />
            </Box>
        );
    }

    return (
        <TemplateView
            editionLevel={editionLevels.NO_EDITION_MODE}
            isClientView
        />
    );
};

TemplateSection.propTypes = {
    isLoading: PropTypes.bool,
};

export default TemplateSection;
