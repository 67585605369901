import React from 'react';

import { useTheme, styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, Typography } from 'sunwise-ui';

import Rhombus from 'resources/Rhombus.svg';

import CompanyLogo from './CompanyLogo';

const Separator = styled(Box)`
    width: 4px;
    height: 100px;
    background: #ffa200;
    mix-blend-mode: normal;
    border-radius: 2px;
`;

const ContactFooter = ({ branding, interactiveProposalData }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const installerEmail = get(interactiveProposalData, 'installer_email', '');
    const installerPhone = get(interactiveProposalData, 'installer_phone', '');

    return (
        <Box
            maxWidth="xxl"
            mt={2}
            sx={{
                backgroundColor: isDarkMode ? '#000' : '#fff',
            }}
        >
            <Grid container justifyContent="center" p={3}>
                <Grid
                    item
                    xs={6}
                    md={3}
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                    }}
                >
                    <CompanyLogo
                        branding={branding}
                        companyLogo={get(
                            interactiveProposalData,
                            'compay_logo_url',
                            null
                        )}
                    />
                </Grid>
                <Grid item xs={9} md={4}>
                    <Grid container alignItems="center">
                        <Grid
                            item
                            xs={2}
                            display="flex"
                            justifyContent="center"
                        >
                            <Separator
                                sx={{ backgroundColor: 'primary.main' }}
                            />
                        </Grid>
                        <Grid item xs={16}>
                            <Typography variant="h4">{t('Contact')}</Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    mt: 1,
                                }}
                            >
                                {installerEmail}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    mt: 1,
                                }}
                            >
                                {installerPhone}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container mt={1} justifyContent="center">
                <Grid item xs={16} md={13}>
                    <Divider />
                </Grid>
            </Grid>
            <Box p={1} sx={{ width: '100%' }}>
                <img src={Rhombus} width="100%" />
            </Box>
        </Box>
    );
};

ContactFooter.propTypes = {
    branding: PropTypes.object,
    interactiveProposalData: PropTypes.object,
};

export default ContactFooter;
