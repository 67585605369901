import React, { useRef, useState, useEffect, Suspense } from 'react';

import { CssBaseline } from '@mui/material';
import {
    createTheme,
    StyledEngineProvider,
    ThemeProvider,
} from '@mui/material/styles';
import { GoogleApiWrapper } from 'google-maps-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    breakpoints,
    customShadows,
    componentsOverride,
    palette,
    shadows,
    shape,
    typography,
} from 'sunwise-ui';

import MainLoading from 'common/modules/loadingView/MainLoading';
import LoadingProcessView from 'common/modules/loadingView/ProcessView';
import { LoadingProvider, GeneralProvider } from 'common/utils/contexts';
import { handleBuildTheme } from 'common/utils/helpers';
import 'react-toastify/dist/ReactToastify.css';
import showToast from 'common/utils/showToast';

import * as interactiveProposalActions from './modules/interactiveProposalPreview/actions';
import * as interactiveProposalSelectors from './modules/interactiveProposalPreview/selectors';
import main from './modules/main';
import {
    components as themeDarkComponents,
    palette as themeDarkPalette,
} from './theme-dark';

const buildConnectionToast = (offlineToast, setOfflineToast, t) => (evt) => {
    if (evt.type === 'online') {
        if (offlineToast && toast.isActive(offlineToast)) {
            toast.dismiss(offlineToast);
        }
        setTimeout(() => {
            showToast({
                title: t('On-line'),
                body: t(
                    'Now you can save your changes. Your connection has been reestablished'
                ),
                type: 'online',
                autoClose: 3000,
            });
        }, 300);
    } else {
        setOfflineToast(
            showToast({
                autoClose: false,
                closeButton: false,
                closeOnClick: false,
                draggable: false,
                title: t('Offline'),
                body: t(
                    'You will not be able to save changes until your connection is reestablished'
                ),
                type: 'offline',
            })
        );
    }
};

const App = ({
    branding,
    history,
    selectedTheme = 'light',
    setBranding,
    setTheme,
}) => {
    const { t } = useTranslation();
    const loadingProcessRef = useRef(null);
    const [providerValue, setProviderValue] = useState({});
    const [offlineToast, setOfflineToast] = useState(null);

    const { darkPalette, lightPalette } = handleBuildTheme({
        baseDarkPalette: themeDarkPalette,
        baseLightPalette: palette,
        branding,
    });

    const themeOptions = {
        breakpoints,
        customShadows,
        palette: selectedTheme === 'light' ? lightPalette : darkPalette,
        shadows,
        shape,
        typography,
    };
    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);
    if (selectedTheme === 'dark')
        theme.components = { ...themeDarkComponents(theme).components };

    useEffect(() => {
        if (loadingProcessRef.current !== null) {
            const { openLoading, closeLoading } = loadingProcessRef.current;
            setProviderValue({ openLoading, closeLoading });
        }
    }, [loadingProcessRef]);

    useEffect(() => {
        const handlerToast = buildConnectionToast(
            offlineToast,
            setOfflineToast,
            t
        );
        window.addEventListener('offline', handlerToast);
        window.addEventListener('online', handlerToast);
        return () => {
            window.removeEventListener('offline', handlerToast);
            window.removeEventListener('online', handlerToast);
        };
    }, [offlineToast, setOfflineToast]);

    useEffect(() => {
        const storageTheme = localStorage.getItem('theme') || 'light';
        setTheme(storageTheme);
        const storageBranding = localStorage.getItem('branding');
        if (storageBranding && storageBranding !== 'undefined') {
            const branding = JSON.parse(storageBranding);
            setBranding(branding);
        }
    }, []);

    return (
        <>
            <Suspense fallback={null}>
                <LoadingProcessView
                    ref={loadingProcessRef}
                    selectedTheme={selectedTheme}
                />
                <MainLoading selectedTheme={selectedTheme} />
                <Router history={history}>
                    <LoadingProvider value={providerValue}>
                        <GeneralProvider value={{}}>
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={theme}>
                                    <CssBaseline />
                                    <main.Container />
                                </ThemeProvider>
                            </StyledEngineProvider>
                        </GeneralProvider>
                    </LoadingProvider>
                    <ToastContainer />
                </Router>
            </Suspense>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    branding: interactiveProposalSelectors.getBrandingConfig,
    selectedTheme: interactiveProposalSelectors.getTheme,
});

const mapDispatchToProps = (dispatch) => ({
    setBranding: (value) =>
        dispatch(interactiveProposalActions.setBranding(value)),
    setTheme: (value) => dispatch(interactiveProposalActions.setTheme(value)),
});

App.propTypes = {
    branding: PropTypes.object,
    google: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    selectedTheme: PropTypes.string,
    setBranding: PropTypes.func,
    setTheme: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    GoogleApiWrapper({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        libraries: ['drawing', 'geometry'],
        LoadingContainer: () => null,
    })
)(withRouter(App));
