import update from 'immutability-helper';

import {
    APPROVE_INTERACTIVE_PROPOSAL,
    APPROVE_INTERACTIVE_PROPOSAL_FAILURE,
    APPROVE_INTERACTIVE_PROPOSAL_SUCCESS,
    DELETE_INTERACTIVE_PROPOSAL_SIGNATURE,
    DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
    DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
    FETCH_INTERACTIVE_PROPOSAL,
    FETCH_INTERACTIVE_PROPOSAL_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_SUCCESS,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS,
    FETCH_INTERACTIVE_PROPOSAL_SIGNATURE,
    FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
    FETCH_SMART_DOCUMENT_CONTENT,
    FETCH_SMART_DOCUMENT_CONTENT_FAILURE,
    FETCH_SMART_DOCUMENT_CONTENT_SUCCESS,
    INITIAL_VALUES,
    RESET,
    SAVE_INTERACTIVE_PROPOSAL_SIGNATURE,
    SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
    SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
    TOGGLE_TOOLBAR_MESSAGE,
} from './actionTypes';

const INITIAL_STATE = {
    approvalInteractiveProposal: {
        errors: [],
        isApproving: false,
    },
    deleteInteractiveProposalSignature: {
        errors: [],
        isDeleting: false,
    },
    saveInteractiveProposalSignature: {
        errors: [],
        isSaving: false,
    },
    initialValues: {
        email: '',
        is_approved: false,
    },
    interactiveProposal: {
        data: {},
        errors: [],
        hasError: false,
        isFetching: false,
    },
    interactiveProposalContent: {
        data: {},
        errors: [],
        isFetching: false,
    },
    interactiveProposalSignature: {
        data: {},
        errors: {},
        isFetching: false,
    },
    smartDocumentContent: {
        data: {},
        errors: [],
        isFetching: false,
    },
    showToolbarMessage: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case APPROVE_INTERACTIVE_PROPOSAL:
            return update(state, {
                approvalInteractiveProposal: {
                    $merge: {
                        errors: [],
                        isApproving: true,
                    },
                },
            });

        case APPROVE_INTERACTIVE_PROPOSAL_FAILURE:
            return update(state, {
                approvalInteractiveProposal: {
                    $merge: {
                        errors: action.payload,
                        isApproving: false,
                    },
                },
            });

        case APPROVE_INTERACTIVE_PROPOSAL_SUCCESS:
            return update(state, {
                approvalInteractiveProposal: {
                    $merge: {
                        data: action.payload,
                        isApproving: false,
                    },
                },
            });

        case DELETE_INTERACTIVE_PROPOSAL_SIGNATURE:
            return update(state, {
                deleteInteractiveProposalSignature: {
                    $merge: {
                        errors: [],
                        isDeleting: true,
                    },
                },
            });

        case DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE:
            return update(state, {
                deleteInteractiveProposalSignature: {
                    $merge: {
                        errors: action.payload,
                        isDeleting: false,
                    },
                },
            });

        case DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS:
            return update(state, {
                deleteInteractiveProposalSignature: {
                    $merge: {
                        data: action.payload,
                        isDeleting: false,
                    },
                },
            });

        case FETCH_INTERACTIVE_PROPOSAL:
            return update(state, {
                interactiveProposal: {
                    $merge: {
                        error: {},
                        isFetching: true,
                    },
                },
            });

        case FETCH_INTERACTIVE_PROPOSAL_FAILURE:
            return update(state, {
                interactiveProposal: {
                    $merge: {
                        error: action.payload,
                        hasError: true,
                        isFetching: false,
                    },
                },
            });

        case FETCH_INTERACTIVE_PROPOSAL_SUCCESS:
            return update(state, {
                interactiveProposal: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_INTERACTIVE_PROPOSAL_CONTENT:
            return update(state, {
                interactiveProposalContent: {
                    $merge: {
                        errors: [],
                        isFetching: true,
                    },
                },
            });

        case FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE:
            return update(state, {
                interactiveProposalContent: {
                    $merge: {
                        errors: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS:
            return update(state, {
                interactiveProposalContent: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_INTERACTIVE_PROPOSAL_SIGNATURE:
            return update(state, {
                interactiveProposalSignature: {
                    $merge: {
                        errors: [],
                        isFetching: true,
                    },
                },
            });

        case FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE:
            return update(state, {
                interactiveProposalSignature: {
                    $merge: {
                        errors: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS:
            return update(state, {
                interactiveProposalSignature: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_SMART_DOCUMENT_CONTENT:
            return update(state, {
                smartDocumentContent: {
                    $merge: {
                        errors: [],
                        isFetching: true,
                    },
                },
            });

        case FETCH_SMART_DOCUMENT_CONTENT_FAILURE:
            return update(state, {
                smartDocumentContent: {
                    $merge: {
                        errors: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_SMART_DOCUMENT_CONTENT_SUCCESS:
            return update(state, {
                smartDocumentContent: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case INITIAL_VALUES:
            return update(state, {
                initialValues: { $merge: action.payload },
            });

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case SAVE_INTERACTIVE_PROPOSAL_SIGNATURE:
            return update(state, {
                saveInteractiveProposalSignature: {
                    $merge: {
                        errors: [],
                        isSaving: true,
                    },
                },
            });

        case SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE:
            return update(state, {
                saveInteractiveProposalSignature: {
                    $merge: {
                        errors: action.payload,
                        isSaving: false,
                    },
                },
            });

        case SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS:
            return update(state, {
                saveInteractiveProposalSignature: {
                    $merge: {
                        data: action.payload,
                        isSaving: false,
                    },
                },
            });

        case TOGGLE_TOOLBAR_MESSAGE:
            return update(state, {
                showToolbarMessage: {
                    $set: action.payload,
                },
            });

        default:
            return state;
    }
}
