import { NAME } from './constants';

export const APPROVE_INTERACTIVE_PROPOSAL = `${NAME}/APPROVE_INTERACTIVE_PROPOSAL`;
export const APPROVE_INTERACTIVE_PROPOSAL_FAILURE = `${NAME}/APPROVE_INTERACTIVE_PROPOSAL_FAILURE`;
export const APPROVE_INTERACTIVE_PROPOSAL_SUCCESS = `${NAME}/APPROVE_INTERACTIVE_PROPOSAL_SUCCESS`;

export const DELETE_INTERACTIVE_PROPOSAL_SIGNATURE = `${NAME}/DELETE_INTERACTIVE_PROPOSAL_SIGNATURE`;
export const DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE = `${NAME}/DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE`;
export const DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS = `${NAME}/DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS`;

export const DOWNLOAD_DATASHEET = `${NAME}/DOWNLOAD_DATASHEET`;
export const DOWNLOAD_DATASHEET_FAILURE = `${NAME}/DOWNLOAD_DATASHEET_FAILURE`;

export const FETCH_BRANDING = `${NAME}/FETCH_BRANDING`;
export const FETCH_BRANDING_FAILURE = `${NAME}/FETCH_BRANDING_FAILURE`;
export const FETCH_BRANDING_SUCCESS = `${NAME}/FETCH_BRANDING_SUCCESS`;

export const FETCH_ELECTRIC_BILLS = `${NAME}/FETCH_ELECTRIC_BILLS`;
export const FETCH_ELECTRIC_BILLS_FAILURE = `${NAME}/FETCH_ELECTRIC_BILLS_FAILURE`;
export const FETCH_ELECTRIC_BILLS_SUCCESS = `${NAME}/FETCH_ELECTRIC_BILLS_SUCCESS`;

export const FETCH_FINANCING_TEMPLATE = `${NAME}/FETCH_FINANCING_TEMPLATE`;
export const FETCH_FINANCING_TEMPLATE_FAILURE = `${NAME}/FETCH_FINANCING_TEMPLATE_FAILURE`;
export const FETCH_FINANCING_TEMPLATE_SUCCESS = `${NAME}/FETCH_FINANCING_TEMPLATE_SUCCESS`;

export const FETCH_INTERACTIVE_PROPOSAL = `${NAME}/FETCH_INTERACTIVE_PROPOSAL`;
export const FETCH_INTERACTIVE_PROPOSAL_FAILURE = `${NAME}/FETCH_INTERACTIVE_PROPOSAL_FAILURE`;
export const FETCH_INTERACTIVE_PROPOSAL_SUCCESS = `${NAME}/FETCH_INTERACTIVE_PROPOSAL_SUCCESS`;

export const FETCH_INTERACTIVE_PROPOSAL_CONTENT = `${NAME}/FETCH_INTERACTIVE_PROPOSAL_CONTENT`;
export const FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE = `${NAME}/FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE`;
export const FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS = `${NAME}/FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS`;

export const FETCH_INTERACTIVE_PROPOSAL_SIGNATURE = `${NAME}/FETCH_INTERACTIVE_PROPOSAL_SIGNATURE`;
export const FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE = `${NAME}/FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE`;
export const FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS = `${NAME}/FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS`;

export const FETCH_SMART_DOCUMENT_CONTENT = `${NAME}/FETCH_SMART_DOCUMENT_CONTENT`;
export const FETCH_SMART_DOCUMENT_CONTENT_FAILURE = `${NAME}/FETCH_SMART_DOCUMENT_CONTENT_FAILURE`;
export const FETCH_SMART_DOCUMENT_CONTENT_SUCCESS = `${NAME}/FETCH_SMART_DOCUMENT_CONTENT_SUCCESS`;

export const INITIAL_VALUES = `${NAME}/INITIAL_VALUES`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_INTERACTIVE_PROPOSAL_SIGNATURE = `${NAME}/SAVE_INTERACTIVE_PROPOSAL_SIGNATURE`;
export const SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE = `${NAME}/SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE`;
export const SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS = `${NAME}/SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS`;

export const SAVE_VISITS = `${NAME}/SAVE_VISITS`;
export const SAVE_VISITS_FAILURE = `${NAME}/SAVE_VISITS_FAILURE`;
export const SAVE_VISITS_SUCCESS = `${NAME}/SAVE_VISITS_SUCCESS`;

export const SET_BRANDING = `${NAME}/SET_BRANDING`;
export const SET_IS_OPEN_APPROVAL_MODAL = `${NAME}/SET_IS_OPEN_APPROVAL_MODAL`;
export const SET_THEME = `${NAME}/SET_THEME`;

export const TOGGLE_TOOLBAR_MESSAGE = `${NAME}/TOGGLE_TOOLBAR_MESSAGE`;
