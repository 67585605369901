import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import reject from './reject';

export default (token) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Continue'),
        messages: [
            i18next.t(
                'By not authorizing the bureau consultation the integrator will not be able to continue with the procedure. Are you sure you do not authorize?'
            ),
        ],
        onSuccess: () => dispatch(reject(token)),
        title: i18next.t('Confirm'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
