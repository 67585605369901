export const CAN_VIEW = 0;
export const CAN_DELETE = 1;
export const CAN_UPDATE = 2;
export const CAN_UPDATE_DELETE = 3;
export const CAN_CREATE = 4;
export const CAN_CREATE_DELETE = 5;
export const CAN_CREATE_UPDATE = 6;
export const ALL_PERMISSIONS = 7;
export const DEFAULT_NO_PERMISSIONS = {
    canCreate: false,
    canDelete: false,
    canUpdate: false,
    canView: false,
};
