import {
    add,
    differenceInDays,
    differenceInMinutes,
    format,
    formatDistance,
    isAfter,
    isBefore,
    isExists,
    parse,
    setMonth,
    startOfYear,
    sub,
} from 'date-fns';

import { getLocaleEquivalenceForDateFns } from 'common/utils/helpers/multiregion';

const { getTimezoneOffset, utcToZonedTime } = require('date-fns-tz');

export const addDate = (date, duration) => add(date, duration);
export const differenceInDaysDate = (dateLeft, dateRight) =>
    differenceInDays(dateLeft, dateRight);

export const differenceInMinutesDate = (dateLeft, dateRight) =>
    differenceInMinutes(dateLeft, dateRight);

export const formatDate = (date, formatDate = 'dd/MM/yyyy') =>
    format(date, formatDate, { locale: getLocaleEquivalenceForDateFns() });

export const formatDistanceDate = (date, baseDate, options) =>
    formatDistance(date, baseDate, {
        ...options,
        locale: getLocaleEquivalenceForDateFns(),
    });

export const getDateByTimezoneOffset = (date = new Date(), timezone) =>
    new Date(date.getTime() + getTimezoneOffset(timezone));

export const isAfterDate = (date, dateToCompare) =>
    isAfter(date, dateToCompare);

export const isBeforeDate = (date, dateToCompare) =>
    isBefore(date, dateToCompare);

export const isExistDate = (year, month, day) => isExists(year, month, day);

export const parseDate = (date = new Date(), formatDate) =>
    parse(date, formatDate, new Date(), {
        locale: getLocaleEquivalenceForDateFns(),
    });

export const setMonthDate = (date, month) => setMonth(date, month);

export const startOfYearDate = (date) => startOfYear(date);

export const subDate = (date, duration) => sub(date, duration);

export const zonedDate = (date, timezone) => utcToZonedTime(date, timezone);
