import { reject } from 'common/api/v1/burocredito';
import alerts from 'common/modules/alerts';

import { AUTH, AUTH_SUCCESS, AUTH_FAILURE } from '../actionTypes';
import { BUREAU_STEPS } from '../constants';

import setCurrentStep from './setCurrentStep';

export default (token) => (dispatch) => {
    dispatch({ type: AUTH });

    reject(token)
        .then((response) => {
            dispatch({ type: AUTH_SUCCESS, payload: response.data });
            dispatch(setCurrentStep(BUREAU_STEPS.COMPLETE));
            dispatch(alerts.actions.close());
        })
        .catch((error) => {
            dispatch({ type: AUTH_FAILURE, payload: error });
        });
};
