import { getBranding } from 'common/api/interactiveProposal';

import {
    FETCH_BRANDING,
    FETCH_BRANDING_FAILURE,
    FETCH_BRANDING_SUCCESS,
} from '../actionTypes';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch({ type: FETCH_BRANDING });

        getBranding(id)
            .then((response) => {
                dispatch({
                    type: FETCH_BRANDING_SUCCESS,
                    payload: response?.data?.branding,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_BRANDING_FAILURE,
                    payload: error,
                });
            })
            .finally(resolve);
    });
