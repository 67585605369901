import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    Box,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    Typography,
} from 'sunwise-ui';

import { getFormattedDate } from '../helpers';

import ApprovalForm from './ApprovalForm';

const ApprovalSection = ({
    approveEmail,
    deleteInteractiveProposalSignature,
    hasApproved,
    hasSignature,
    interactiveProposalSignatureData,
    offerId,
    saveInteractiveProposalSignature,
    setShowSignatureArea,
    showSignatureArea,
    updatedAt,
}) => {
    const { t } = useTranslation();

    if (hasApproved) {
        return (
            <Box mt={2}>
                <Grid container>
                    <Grid item xs={18}>
                        <Alert
                            icon={<CheckCircleIcon sx={{ color: '#3caf5f' }} />}
                            severity="success"
                        >
                            <Typography variant="body2" fontWeight="bold">
                                {t('You have approved this proposal')}
                            </Typography>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="caption">
                                    {`${t('Approved on')} ${getFormattedDate(
                                        updatedAt
                                    )}`}
                                </Typography>
                                <Typography variant="caption">
                                    {`${t('Sent to')} ${approveEmail}`}
                                </Typography>
                            </Box>
                        </Alert>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid hidden={hasSignature} item xs={18}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showSignatureArea}
                                        color="warning"
                                        onChange={(e) =>
                                            setShowSignatureArea(
                                                e.target.checked
                                            )
                                        }
                                    />
                                }
                                disabled={hasSignature}
                                label={t(
                                    'Add my digital signature to all documents submitted'
                                )}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <ApprovalForm
            deleteInteractiveProposalSignature={
                deleteInteractiveProposalSignature
            }
            interactiveProposalSignatureData={interactiveProposalSignatureData}
            offerId={offerId}
            saveInteractiveProposalSignature={saveInteractiveProposalSignature}
        />
    );
};

ApprovalSection.propTypes = {
    approveEmail: PropTypes.string,
    deleteInteractiveProposalSignature: PropTypes.func,
    hasApproved: PropTypes.bool,
    hasSignature: PropTypes.bool,
    interactiveProposalSignatureData: PropTypes.object,
    offerId: PropTypes.string,
    saveInteractiveProposalSignature: PropTypes.func,
    setShowSignatureArea: PropTypes.func,
    showSignatureArea: PropTypes.bool,
    updatedAt: PropTypes.string,
};

export default ApprovalSection;
