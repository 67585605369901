import { styled } from '@mui/material/styles';
import { Container as MaterialContainer } from 'sunwise-ui';

export default styled(MaterialContainer)`
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.3),
            rgba(255, 255, 255, 0.3)
        ),
        #eef4fa;
`;
