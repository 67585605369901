import React, { forwardRef, useImperativeHandle, useState } from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import Logo from './Logo';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  0%, 20%, 40%, 60%, 80%, 100% {
    opacity: 1;
  }

  10%, 30%, 50%, 70%, 90% {
    opacity: 0;
  }

  100% {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
    align-items: center;
    background: ${({ mode }) => (mode === 'dark' ? '#000000ee' : '#002438ee')};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: ${({ visible }) => (visible ? '1' : '0')};
    position: fixed;
    top: 0;
    transition: ${({ visible }) =>
        visible
            ? 'opacity 0.25s linear, visibility 0s linear'
            : 'opacity 0.25s linear, visibility 0s linear 0.25s'};
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    width: 100%;
    z-index: 9999;
`;

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 300px;
`;

const ImageContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 64px;
    justify-content: center;
    margin-bottom: 10px;
    position: relative;
    width: 64px;

    img,
    svg {
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-name: ${rotate};
        animation-timing-function: linear;
        width: 64px;
    }
`;

const MessageText = styled.span`
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.01rem;
    line-height: 18px;
    min-height: 18px;
    text-align: center;
    width: 100%;
`;

const ProcessView = ({ myForwardedRef, selectedTheme }) => {
    const [loadingData, setLoadingData] = useState({
        show: false,
        text: i18next.t('Loading').concat('...'),
    });

    useImperativeHandle(myForwardedRef, () => ({
        openLoading(text = i18next.t('Loading').concat('...')) {
            document.body.style.overflowY = 'hidden';
            setLoadingData({ text, show: true });
        },
        closeLoading() {
            document.body.style.overflowY = 'auto';
            setLoadingData({ text: '', show: false });
        },
    }));

    return (
        <Wrapper mode={selectedTheme} visible={loadingData.show}>
            <Container>
                <ImageContainer>
                    <Logo />
                </ImageContainer>
                <MessageText>{loadingData.text}</MessageText>
            </Container>
        </Wrapper>
    );
};

ProcessView.propTypes = {
    myForwardedRef: PropTypes.object,
    selectedTheme: PropTypes.string,
};

const ProcessViewForwared = forwardRef((props, ref) => (
    <ProcessView {...props} myForwardedRef={ref} />
));

ProcessViewForwared.displayName = 'ProcessViewForwared';

export default ProcessViewForwared;
