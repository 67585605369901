import React from 'react';

import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, Typography } from 'sunwise-ui';

import { addDefaultSrc, handleFileURL } from 'common/utils/helpers';

import Rhombus from 'resources/Rhombus.svg';

import WrapperHeader from './WrapperHeader';
import WrapperLogo from './WrapperLogo';

const Separator = styled(Box)`
    width: 4px;
    height: 100px;
    background: #ffa200;
    mix-blend-mode: normal;
    border-radius: 2px;
`;

const Footer = ({ interactiveProposalData }) => {
    const { t } = useTranslation();

    const installerEmail = get(interactiveProposalData, 'installer_email', '');
    const installerPhone = get(interactiveProposalData, 'installer_phone', '');
    const companyLogo = get(
        interactiveProposalData,
        'compay_logo_url',
        'https://placehold.jp/100x100.png?text=your+logo'
    );

    return (
        <WrapperHeader maxWidth="xxl" mt={3}>
            <Grid container justifyContent="center" p={3}>
                <Grid
                    item
                    xs={6}
                    md={3}
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                    }}
                >
                    <WrapperLogo>
                        <img
                            onError={(e) => addDefaultSrc(e, 200)}
                            src={handleFileURL(
                                companyLogo,
                                process.env.REACT_APP_S3_MEDIA_PATH
                            )}
                        />
                    </WrapperLogo>
                </Grid>
                <Grid item xs={9} md={4}>
                    <Grid container alignItems="center">
                        <Grid
                            item
                            xs={2}
                            display="flex"
                            justifyContent="center"
                        >
                            <Separator />
                        </Grid>
                        <Grid item xs={16}>
                            <Typography
                                variant="h4"
                                sx={{ color: 'rgba(0, 0, 0, 0.9)' }}
                            >
                                {t('Contact')}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    mt: 1,
                                }}
                            >
                                {installerEmail}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    mt: 1,
                                }}
                            >
                                {installerPhone}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container mt={1} justifyContent="center">
                <Grid item xs={16} md={13}>
                    <Divider />
                </Grid>
            </Grid>
            <Box p={1} sx={{ width: '100%' }}>
                <img src={Rhombus} width="100%" />
            </Box>
        </WrapperHeader>
    );
};

Footer.propTypes = {
    interactiveProposalData: PropTypes.object,
};

export default Footer;
