import API from '../.';
const ENTITY = 'datasheets';

export const getProductDatasheets = (productKey, productId) =>
    API.get(`/api/v1/${ENTITY}/${productKey}/${productId}/`);
export const downloadDatasheet = (name) =>
    API.get(`/api/v1/pdf/sunpal-datasheets/${name}`, {
        responseType: 'blob',
    });
export const getCommercialOfferDatasheets = (id) =>
    API.get(`/api/v1/offer-datasheets/${id}`);
