import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from 'sunwise-ui';

import alerts from 'common/modules/alerts';

import background from 'resources/background.jpg';

const CreditBureau = ({ children }) => (
    <>
        <Grid container sx={{ height: 'calc(100% + 16px)' }}>
            <Grid
                item
                xs={18}
                md
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {children}
            </Grid>
            <Grid
                item
                display={{ xs: 'none', md: 'initial' }}
                md
                sx={{
                    backgroundColor: '#00f',
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                }}
            />
        </Grid>

        <alerts.Container />
    </>
);

CreditBureau.propTypes = { children: PropTypes.object };

export default CreditBureau;
