import { deleInteractiveProposalSignature } from 'common/api/interactiveProposal';

import {
    DELETE_INTERACTIVE_PROPOSAL_SIGNATURE,
    DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
    DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
} from '../actionTypes';

import fetchInteractiveProposal from './fetchInteractiveProposal';
import fetchInteractiveProposalSignature from './fetchInteractiveProposalSignature';

export default (offerId) => (dispatch) => {
    dispatch({ type: DELETE_INTERACTIVE_PROPOSAL_SIGNATURE });

    deleInteractiveProposalSignature(offerId)
        .then((response) => {
            dispatch({
                type: DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
                payload: response.data.data,
            });
            dispatch(fetchInteractiveProposal(offerId));
            dispatch(fetchInteractiveProposalSignature(offerId));
        })
        .catch((error) => {
            dispatch({
                type: DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
                payload: error,
            });
        });
};
