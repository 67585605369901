import update from 'immutability-helper';

import {
    AUTH,
    AUTH_FAILURE,
    AUTH_SUCCESS,
    INITIALIZE_TERMS_FORM,
    LOGIN,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    RESET,
    RESET_TERMS_FORM,
    RESET_AUTH_FORM,
    RESET_LOGIN_FORM,
    SET_STEP,
} from './actionTypes';

const INITIAL_STATE = {
    authorization: {
        data: null,
        errors: null,
        isLoading: false,
    },
    initialValues: {
        username: null,
        password: null,
    },
    initialValuesAuthorizeForm: {
        authorize: false,
        nip: null,
    },
    initialValuesTermsForm: {
        currentNip: null,
        accept_terms: false,
        nip: null,
    },
    login: {
        data: null,
        errors: null,
        isLoading: false,
    },
    step: 'LOGIN',
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTH:
            return update(state, {
                authorization: {
                    $merge: {
                        data: null,
                        errors: null,
                        isLoading: true,
                    },
                },
            });

        case AUTH_SUCCESS:
            return update(state, {
                authorization: {
                    $merge: {
                        data: action.payload,
                        errors: null,
                        isLoading: false,
                    },
                },
            });

        case AUTH_FAILURE:
            return update(state, {
                authorization: {
                    $merge: {
                        data: null,
                        errors: action.payload,
                        isLoading: false,
                    },
                },
            });

        case INITIALIZE_TERMS_FORM:
            return update(state, {
                initialValuesTermsForm: { $merge: action.payload },
            });

        case LOGIN:
            return update(state, {
                login: {
                    $merge: {
                        data: null,
                        errors: null,
                        isLoading: true,
                    },
                },
            });

        case LOGIN_SUCCESS:
            return update(state, {
                login: {
                    $merge: {
                        data: action.payload,
                        errors: null,
                        isLoading: false,
                    },
                },
            });

        case LOGIN_FAILURE:
            return update(state, {
                login: {
                    $merge: {
                        data: null,
                        errors: action.payload,
                        isLoading: false,
                    },
                },
            });

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case RESET_TERMS_FORM:
            return update(state, {
                initialValuesTermsForm: {
                    $set: INITIAL_STATE.initialValuesTermsForm,
                },
            });

        case RESET_AUTH_FORM:
            return update(state, {
                initialValuesAuthorizeForm: {
                    $set: INITIAL_STATE.initialValuesAuthorizeForm,
                },
            });

        case RESET_LOGIN_FORM:
            return update(state, {
                initialValues: { $set: INITIAL_STATE.initialValues },
            });

        case SET_STEP:
            return update(state, { step: { $set: action.payload } });

        default:
            return state;
    }
}
