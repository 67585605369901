import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from '../actions';
import * as selectors from '../selectors';

import ApprovalSection from './ApprovalSection';
import CustomerSignature from './CustomerSignature';
import SignatureArea from './SignatureArea';

const ApprovalModal = ({
    deleteInteractiveProposalSignature,
    handleClickCloseModal,
    hasApproved,
    hasSignature,
    interactiveProposalSignatureData,
    isOpenModal,
    offerId,
    saveInteractiveProposalSignature,
    setShowSignatureArea,
    showSignatureArea,
}) => {
    const { t } = useTranslation();
    return (
        <Dialog
            onClose={handleClickCloseModal}
            open={isOpenModal}
            size="md"
            title={t('Approval')}
        >
            <ApprovalSection
                approveEmail={get(
                    interactiveProposalSignatureData,
                    'email',
                    ''
                )}
                deleteInteractiveProposalSignature={
                    deleteInteractiveProposalSignature
                }
                hasApproved={hasApproved}
                hasSignature={hasSignature}
                interactiveProposalSignatureData={
                    interactiveProposalSignatureData
                }
                offerId={offerId}
                saveInteractiveProposalSignature={
                    saveInteractiveProposalSignature
                }
                setShowSignatureArea={setShowSignatureArea}
                showSignatureArea={showSignatureArea}
                updatedAt={get(interactiveProposalSignatureData, 'updated', '')}
            />
            <SignatureArea
                deleteInteractiveProposalSignature={
                    deleteInteractiveProposalSignature
                }
                interactiveProposalSignatureData={
                    interactiveProposalSignatureData
                }
                isHidden={!showSignatureArea || hasSignature}
                offerId={offerId}
                saveInteractiveProposalSignature={
                    saveInteractiveProposalSignature
                }
            />
            <CustomerSignature
                hasSignature={hasSignature}
                signatureArchive={get(
                    interactiveProposalSignatureData,
                    'signature_archive',
                    ''
                )}
                signatureUpdated={get(
                    interactiveProposalSignatureData,
                    'updated',
                    ''
                )}
            />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    isOpenModal: selectors.getIsOpenApprovalModal,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickCloseModal: () => dispatch(actions.openApprovalModal(false)),
});

ApprovalModal.propTypes = {
    deleteInteractiveProposalSignature: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    hasApproved: PropTypes.bool,
    hasSignature: PropTypes.bool,
    interactiveProposalSignatureData: PropTypes.object,
    isOpenModal: PropTypes.bool,
    offerId: PropTypes.string,
    saveInteractiveProposalSignature: PropTypes.func,
    setShowSignatureArea: PropTypes.func,
    showSignatureArea: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalModal);
