import React from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { types } from 'sunwise-template-core';
import {
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Skeleton,
} from 'sunwise-ui';

import SideMenuCard from './SideMenuCard';

const LoaderText = ({ children, isLoading }) => {
    if (isLoading) return <Skeleton variant="text" sx={{ fontSize: '1rem' }} />;
    return <>{children}</>;
};

LoaderText.propTypes = {
    children: PropTypes.object,
    isLoading: PropTypes.bool,
};

const ProposalCard = ({
    handleClickDocumentItem,
    isDisabled,
    proposalId,
    proposalName,
    selectedDocumentId,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <SideMenuCard
            icon={<ArticleIcon sx={{ color: 'primary.main' }} />}
            title={t('Proposal')}
        >
            <List disablePadding>
                <ListItem
                    disabled={isDisabled || proposalId === selectedDocumentId}
                    disablePadding
                    selected={proposalId === selectedDocumentId}
                    sx={{
                        borderRadius: '8px',
                        mb: '5px',
                        '&.Mui-selected': {
                            backgroundColor:
                                theme.palette.mode === 'dark'
                                    ? '#000'
                                    : 'rgba(31, 60, 83, 0.13)',
                        },
                    }}
                >
                    <ListItemButton
                        onClick={() =>
                            handleClickDocumentItem({
                                id: proposalId,
                                name: proposalName,
                                type: types.ONE_PROPOSAL_TYPE,
                            })
                        }
                        sx={{ borderRadius: '8px' }}
                    >
                        <ListItemText
                            primary={proposalName}
                            sx={{
                                '.MuiListItemText-primary': {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </SideMenuCard>
    );
};

ProposalCard.propTypes = {
    handleClickDocumentItem: PropTypes.func,
    isDisabled: PropTypes.bool,
    proposalId: PropTypes.string,
    proposalName: PropTypes.string,
    selectedDocumentId: PropTypes.string,
};

export default ProposalCard;
