import React from 'react';

export const _LoadingContext = React.createContext({});
export const _GeneralContext = React.createContext({});

export const LoadingProvider = _LoadingContext.Provider;
export const LoadingConsumer = _LoadingContext.Consumer;
export const LoadingContext = _LoadingContext;

export const GeneralProvider = _GeneralContext.Provider;
export const GeneralConsumer = _GeneralContext.Consumer;
export const GeneralContext = _GeneralContext;
