import React from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Box, Typography } from 'sunwise-ui';

import NotFoundIcon from 'resources/notFoundIcon.svg';

import Footer from './DefaultLayout/Footer';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 53px);
    padding: 30px;
`;

const PageNotFoundLayout = () => {
    const { t } = useTranslation();

    return (
        <>
            <Wrapper>
                <Box
                    sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}
                >
                    <Typography variant="h3" paragraph>
                        {t('Page not found')}!
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                        {t(
                            "Sorry, we couldn't find the page you are looking for. Maybe you misspelled the url? Make sure you check your spelling"
                        )}
                        .
                    </Typography>

                    <Box
                        component="img"
                        src={NotFoundIcon}
                        sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
                    />
                </Box>
            </Wrapper>
            <Footer />
        </>
    );
};

export default PageNotFoundLayout;
