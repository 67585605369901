import * as selectors from '../selectors';

import close from './close';

export default (response) => (dispatch, getState) => {
    const state = getState();
    const onSuccess = selectors.getOnSuccess(state);

    dispatch(close());

    if (onSuccess) {
        onSuccess(response);
    }
};
