import { css } from 'styled-components';

export const media = {
    sm: (...args) => css`
        @media (min-width: 576px) {
            ${css(...args)}
        }
    `,
    md: (...args) => css`
        @media (min-width: 768px) {
            ${css(...args)}
        }
    `,
    lg: (...args) => css`
        @media (min-width: 992px) {
            ${css(...args)}
        }
    `,
    xl: (...args) => css`
        @media (min-width: 1200px) {
            ${css(...args)}
        }
    `,
};

export const scroll = {
    custom: () => css`
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background: #004167;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #004167;
        }

        &::-webkit-scrollbar-thumb:active {
            background-color: #004167;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track:hover,
        &::-webkit-scrollbar-track:active {
            background: transparent;
        }
    `,
    object: {
        '&::-webkit-scrollbar': {
            background: 'transparent',
            height: '8px',
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#004167',
            borderRadius: '4px',
        },
    },
};
