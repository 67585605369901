import axios from 'axios';
import { serialize } from 'object-to-formdata';

const CancelToken = axios.CancelToken;
let cancelableRequests = {};

const API = axios.create({
    baseURL: process.env.REACT_APP_SUNPAL_API_URL,
});

API.cancelAll = () => {
    Object.keys(cancelableRequests).forEach((key) => {
        cancelableRequests[key]();
        delete cancelableRequests[key];
    });
};

API.interceptors.request.use((req) => {
    const token = localStorage.getItem('token');
    const customId = `${new Date().getTime()}`;

    if (!req.headers['Content-Type']) {
        req.headers['Content-Type'] = 'multipart/form-data';
    }

    if (token) {
        req.headers['Authorization'] = `JWT ${token}`;
    }

    if (
        req.headers['Content-Type'] === 'multipart/form-data' &&
        (req.method === 'post' || req.method === 'put')
    ) {
        req.data = serialize(req.data, { indices: true });
    }
    req.customId = customId;
    req.cancelToken = new CancelToken(function executor(c) {
        cancelableRequests[customId] = c;
    });

    return req;
});

API.interceptors.response.use(
    (res) => {
        delete cancelableRequests[res.config.customId];
        return res;
    },
    (error) => {
        if (
            error.response &&
            cancelableRequests[error.response.config.customId]
        ) {
            delete cancelableRequests[error.response.config.customId];
        }
        if (
            error.code === 'ECONNABORTED' ||
            typeof error.response === 'undefined'
        ) {
            const _error = Object.assign({}, error);

            return Promise.reject({
                ..._error,
                data: { errors: ['Ocurrio un error de conexion'] },
                response: {
                    data: { errors: ['Ocurrio un error de conexion'] },
                },
            });
        }

        return Promise.reject(error);
    }
);

export default API;
