import get from 'lodash/get';

import { getInteractiveProposal } from 'common/api/interactiveProposal';

import {
    FETCH_INTERACTIVE_PROPOSAL,
    FETCH_INTERACTIVE_PROPOSAL_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_SUCCESS,
} from '../actionTypes';

import fetchInteractiveProposalSignature from './fetchInteractiveProposalSignature';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch({ type: FETCH_INTERACTIVE_PROPOSAL });

        getInteractiveProposal(id)
            .then((response) => {
                dispatch({
                    type: FETCH_INTERACTIVE_PROPOSAL_SUCCESS,
                    payload: response.data,
                });
                const hasApproved = get(response.data, 'has_approbed', false);
                if (hasApproved) {
                    dispatch(fetchInteractiveProposalSignature(id));
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_INTERACTIVE_PROPOSAL_FAILURE,
                    payload: error,
                });
            })
            .finally(resolve);
    });
