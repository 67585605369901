import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const LayoutComponent = ({
    component: Component,
    hasAccess,
    layout: Layout,
    redirect,
    matchProps,
    rest,
}) => {
    if (!hasAccess) return <Redirect to={redirect} />;

    return (
        <Layout {...rest}>
            <Component {...matchProps} />
        </Layout>
    );
};

LayoutComponent.propTypes = {
    component: PropTypes.func,
    hasAccess: PropTypes.bool,
    layout: PropTypes.func,
    redirect: PropTypes.object,
    matchProps: PropTypes.object,
    rest: PropTypes.object,
};

export default connect(null, null)(LayoutComponent);
