import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { DEFAULT_VALUES } from 'common/constants/themeSettings';
export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const addDefaultSrc = (e, size) =>
    (e.target.src = getPlaceholderImg(size));

export const arraytoDictionary = (array, key) => {
    if (!Array.isArray(array)) return {};

    return array.reduce(
        (acc, current) => ({ ...acc, [current[key]]: current }),
        {}
    );
};

export const fullNameBuild = ({ firstName, lastName, secondSurname }) => {
    let fullName = '';
    if (firstName) {
        fullName += firstName;
    }
    if (lastName) {
        if (fullName.length > 0) fullName += ' ';
        fullName += lastName;
    }
    if (secondSurname) {
        if (fullName.length > 0) fullName += ' ';
        fullName += secondSurname;
    }
    return fullName;
};

export const getFileExtensionByPath = (url) => {
    if (!url) return '';
    if (url.indexOf('.', url.lastIndexOf('/') + 1) === -1) return '';
    return url.split(/[#?]/)[0].split('.').pop().trim();
};

export const getPlaceholderImg = (size = 100, text = i18next.t('No image')) =>
    `https://placehold.jp/${size}x${size}.png?text=${text}`;

const validateRGB = (key, values) => {
    const value = values[key];
    if (!value) return DEFAULT_VALUES[key];
    if (value[0] !== '#') return `#${value}`;
    return value;
};

export const handleBuildTheme = ({
    baseDarkPalette,
    baseLightPalette,
    branding,
    defaultValues = DEFAULT_VALUES,
}) => {
    if (!branding || isEmpty(branding))
        return { darkPalette: baseDarkPalette, lightPalette: baseLightPalette };
    const values = { ...defaultValues, ...branding };
    const _lightPalette = {
        primary: {
            contrastText: validateRGB(
                'light_primary_contrast_text_color',
                values
            ),
            main: validateRGB('light_primary_color', values),
        },
        secondary: {
            contrastText: validateRGB(
                'light_secondary_contrast_text_color',
                values
            ),
            main: validateRGB('light_secondary_color', values),
        },
    };

    const _darkPalette = {
        primary: {
            contrastText: validateRGB(
                'dark_primary_contrast_text_color',
                values
            ),
            main: validateRGB('dark_primary_color', values),
        },
        secondary: {
            contrastText: validateRGB(
                'dark_secondary_contrast_text_color',
                values
            ),
            main: validateRGB('dark_secondary_color', values),
        },
    };

    return {
        darkPalette: { ...baseDarkPalette, ..._darkPalette },
        lightPalette: { ...baseLightPalette, ..._lightPalette },
    };
};

export const handleFileURL = (url, preffix) => {
    const pattern = /^((https):\/\/)/;
    return pattern.test(url) ? url : `${preffix}${url}`;
};

export const hasValue = (object, name) =>
    Object.prototype.hasOwnProperty.call(object, name) &&
    !isEmpty(object[name]);

export const toFixed = (number, fixed) => {
    const fixedValue = Math.pow(10, fixed);
    return parseInt(number * fixedValue) / fixedValue;
};
