import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContainer = styled.div`
    & {
        animation: fadeIn 0.25s;
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    width: 100%;
`;

const OpacityAnimation = ({ children, className }) => (
    <StyledContainer className={className}>{children}</StyledContainer>
);

OpacityAnimation.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    className: PropTypes.string,
};

export default OpacityAnimation;
