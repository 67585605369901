import React from 'react';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { types } from 'sunwise-template-core';
import { Box, Button, Grid, Skeleton, Typography } from 'sunwise-ui';

import { formatDate, parseDate } from 'common/utils/dates';
import { addDefaultSrc, handleFileURL } from 'common/utils/helpers';

import WrapperHeader from './WrapperHeader';
import WrapperLogo from './WrapperLogo';

const Separator = styled(Box)`
    width: 4px;
    height: 130px;
    background: #ffa200;
    mix-blend-mode: normal;
    border-radius: 2px;
    @media (max-width: 992px) {
        height: 210px;
    }
`;

const ProposalResume = ({
    documentType,
    interactiveProposalData,
    isLoading,
    loadingContext,
    offerId,
    renderPdfFile,
    setSelectedDocument,
    templateContent,
}) => {
    const { t } = useTranslation();

    if (isEmpty(interactiveProposalData)) {
        return null;
    }

    const createdAt = get(
        interactiveProposalData,
        'proposal_creation_date',
        ''
    );
    const proposalName = get(interactiveProposalData, 'proposal_name', '');
    const projectName = get(interactiveProposalData, 'project_name', '');
    const clientFullName = get(interactiveProposalData, 'client_full_name', '');
    const companyLogo = get(
        interactiveProposalData,
        'compay_logo_url',
        'https://placehold.jp/100x100.png?text=your+logo'
    );

    return (
        <WrapperHeader maxWidth="xxl">
            <Grid container justifyContent="center" alignItems="center" py={3}>
                <Grid
                    item
                    xs={5}
                    md={3}
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                    }}
                >
                    <WrapperLogo>
                        <img
                            onError={(e) => addDefaultSrc(e, 200)}
                            src={handleFileURL(
                                companyLogo,
                                process.env.REACT_APP_S3_MEDIA_PATH
                            )}
                        />
                    </WrapperLogo>
                </Grid>
                <Grid
                    item
                    xs={13}
                    md={7}
                    sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}
                >
                    <Separator />
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{ width: '100%' }}
                    >
                        <Grid container>
                            <Grid item>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        mb: 0,
                                    }}
                                >
                                    {t('Proposal')}
                                </Typography>
                                {isLoading ? (
                                    <Skeleton
                                        variant="text"
                                        sx={{ fontSize: '1rem' }}
                                    />
                                ) : (
                                    <Typography
                                        variant="h4"
                                        sx={{ color: 'rgba(0, 0, 0, 0.9)' }}
                                    >
                                        {proposalName}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={18} md={8} mt={2}>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        mb: 0,
                                    }}
                                >
                                    {t('Project')}
                                </Typography>
                                {isLoading ? (
                                    <Skeleton
                                        variant="text"
                                        sx={{ fontSize: '1rem' }}
                                    />
                                ) : (
                                    <Typography
                                        variant="body2"
                                        fontWeight="bold"
                                    >
                                        {projectName}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={6} md={4} mt={2}>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        mb: 0,
                                    }}
                                >
                                    {t('Date')}
                                </Typography>
                                {isLoading ? (
                                    <Skeleton
                                        variant="text"
                                        sx={{ fontSize: '1rem' }}
                                    />
                                ) : (
                                    <Typography
                                        variant="body2"
                                        fontWeight="bold"
                                    >
                                        {createdAt
                                            ? formatDate(
                                                  parseDate(
                                                      createdAt,
                                                      'yyyy-MM-dd'
                                                  ),
                                                  'dd/MM/yyyy'
                                              )
                                            : null}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item mt={2}>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        mt: 1,
                                        mb: 0,
                                    }}
                                >
                                    {t('Client')}
                                </Typography>
                                {isLoading ? (
                                    <Skeleton
                                        variant="text"
                                        sx={{ fontSize: '1rem' }}
                                    />
                                ) : (
                                    <Typography
                                        variant="body2"
                                        fontWeight="bold"
                                    >
                                        {clientFullName}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid
                    display="flex"
                    gap={1}
                    item
                    md={5}
                    textAlign="center"
                    xs={18}
                >
                    <Button
                        color="secondary"
                        endIcon={<VisibilityIcon />}
                        onClick={() =>
                            setSelectedDocument({
                                type: types.ONE_PROPOSAL_TYPE,
                                data: JSON.parse(templateContent),
                            })
                        }
                        type="button"
                        variant="outlined"
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        visible={documentType !== types.ONE_PROPOSAL_TYPE}
                    >
                        {t('View proposal')}
                    </Button>
                    <Button
                        color="secondary"
                        endIcon={<FileDownloadIcon />}
                        onClick={() =>
                            renderPdfFile(
                                offerId,
                                proposalName,
                                () =>
                                    loadingContext.openLoading(
                                        t('Downloading PDF').concat('...')
                                    ),
                                () => loadingContext.closeLoading()
                            )
                        }
                        type="button"
                        variant="outlined"
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                    >
                        {t('Download PDF')}
                    </Button>
                </Grid>
            </Grid>
        </WrapperHeader>
    );
};

ProposalResume.propTypes = {
    documentType: PropTypes.number,
    interactiveProposalData: PropTypes.object,
    isLoading: PropTypes.bool,
    loadingContext: PropTypes.object,
    offerId: PropTypes.string,
    renderPdfFile: PropTypes.func,
    setSelectedDocument: PropTypes.func,
    templateContent: PropTypes.string,
};

export default ProposalResume;
