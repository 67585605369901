import i18next from 'i18next';

import { login } from 'common/api/v1/accounts';

import { LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS } from '../actionTypes';
import { BUREAU_STEPS } from '../constants';

import setCurrentStep from './setCurrentStep';

export default (data) => (dispatch) => {
    dispatch({ type: LOGIN });

    login(data)
        .then((response) => {
            if (!response.data || !response.data.token) {
                dispatch({
                    type: LOGIN_FAILURE,
                    payload: [
                        i18next.t(
                            'An error occurred during login. Please try later'
                        ),
                    ],
                });

                return;
            }

            dispatch({
                type: LOGIN_SUCCESS,
                payload: { ...response.data, nip: data.password },
            });
            dispatch(setCurrentStep(BUREAU_STEPS.TERMS_AND_CONDITIONS));
        })
        .catch((error) =>
            dispatch({
                type: LOGIN_FAILURE,
                payload: error?.response?.data?.errors,
            })
        );
};
