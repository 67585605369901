import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    background-color: #fff;
    border: 1px solid #eef4fa;
    box-sizing: border-box;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px rgba(238, 244, 250, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    pointer-events: none;
    opacity: 0.5;
    img {
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
`;

const Detail = styled.div`
    position: absolute;
    bottom: -1px;
    height: 3px;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    background: #ffa200;
    border-radius: 3px 3px 0 0;
`;

const SignatureImage = ({ srcSignature }) => {
    return (
        <Container>
            <img src={srcSignature} />
            <Detail />
        </Container>
    );
};

SignatureImage.propTypes = {
    srcSignature: PropTypes.string,
};

export default SignatureImage;
