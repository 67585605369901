import i18next from 'i18next';
import isNull from 'lodash/isNull';

import { formatDate, parseDate } from 'common/utils/dates';

export const getDatasheetLabel = (key) => {
    const labels = {
        accesories: i18next.t('Accessory', { count: 2 }),
        additionals: i18next.t('Additional', { count: 2 }),
        batteries: i18next.t('Energy backup'),
        inverters: i18next.t('Inverter', { count: 2 }),
        panels: i18next.t('Panel', { count: 2 }),
        structures: i18next.t('Structure', { count: 2 }),
        workforces: i18next.t('Workforce and electrical equipment'),
    };
    return labels[key];
};

export const getDatasheetsByType = (datasheets, type) => {
    const data = datasheets.map((item) => ({ ...item, type: item.type?.key }));
    return data.filter((datasheet) => datasheet.type === type);
};

export const getDatasheetTypes = (datasheets) => {
    const data = [...datasheets].map((item) => item.type?.key);
    return [...new Set(data)];
};

export const getFileNameFromURL = (archive) => {
    if (!isNull(archive)) {
        return archive.substring(archive.lastIndexOf('/') + 1);
    }
    return '';
};

export const getFormattedDate = (date) => {
    if (date) {
        return formatDate(parseDate(date, 'yyyy/MM/dd HH:mm:ss'), 'dd/MM/yyyy');
    }
    return null;
};
