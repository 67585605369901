import React, { useEffect } from 'react';

import { Switch } from 'react-router-dom';

import { LayoutRoute, PageNotFound } from 'common/components';
import {
    DefaultLayout,
    PageNotFoundLayout,
    TwoColumsLayout,
} from 'common/layouts';

import creditBureau from '../creditBureau';
import interactiveProposal from '../interactiveProposal';
import interactiveProposalPreview from '../interactiveProposalPreview';

const Container = () => {
    useEffect(() => {}, []);

    return (
        <Switch>
            <LayoutRoute
                component={creditBureau.Container}
                exact
                layout={TwoColumsLayout}
                path="/credit-bureau"
            />

            <LayoutRoute
                component={interactiveProposalPreview.Container}
                exact
                layout={DefaultLayout}
                path="/interactive/:uid"
            />

            <LayoutRoute
                component={interactiveProposal.Container}
                exact
                layout={DefaultLayout}
                path="/interactive-old/:uid"
            />

            <LayoutRoute
                component={PageNotFound}
                layout={PageNotFoundLayout}
                path="*"
            />
        </Switch>
    );
};

Container.propTypes = {};

export default Container;
