import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { arraytoDictionary, hasValue } from './index';

export const logoutActions = () => {};

export const initSessionActions = () => {
    //const session = getSessionLocalStorage();
};

export const getSessionLocalStorage = () =>
    typeof localStorage.getItem('session') !== 'undefined'
        ? JSON.parse(localStorage.getItem('session'))
        : {};

export const getGroupsLocalStorage = () =>
    typeof localStorage.getItem('groups') !== 'undefined'
        ? JSON.parse(localStorage.getItem('groups'))
        : [];

export const getPermissionsDictionary = () =>
    arraytoDictionary(getGroupsLocalStorage(), 'id');

export const getUserPermits = () => {
    const groups = getGroupsLocalStorage();
    const sessionGroups =
        groups && groups !== 'undefined' && Array.isArray(groups) ? groups : [];

    const groupsFiltered = sessionGroups.filter(
        (item) => item.is_enabled && ![26, 55].includes(item.group_id)
    );

    return groupsFiltered.map((group) => group.group_id);
};

export const getIsLite = () => {
    const session = getSessionLocalStorage();
    return parseInt(get(session, 'company_group.type_pricing', '1')) === 1;
};

export const getIsGuess = () => {
    return !localStorage.getItem('token');
};

export const getToken = () => {
    return localStorage.getItem('token');
};

export const getIsOwner = () => {
    const session = getSessionLocalStorage();
    if (isNil(session)) {
        return false;
    }
    const companyGroup = get(session, 'company_group', null);
    if (isNil(companyGroup)) {
        return false;
    }
    return companyGroup.owner_type === 0 && companyGroup.type === 0;
};

export const getIsValidLogin = () => {
    const groups = getGroupsLocalStorage();
    const session = getSessionLocalStorage();
    return (
        !isEmpty(groups) && !isEmpty(session) && !isEmpty(session.company_group)
    );
};

export const getCountryCurrency = () => {
    const session = getSessionLocalStorage();
    return get(session, 'currency_company_locale', {});
};

export const getMaximumDiscount = () => {
    const session = getSessionLocalStorage();
    return get(session, 'company_group.maximum_discount', 0);
};

export const getCountryCurrencyIso = () => {
    const currency = getCountryCurrency();
    if (!hasValue(currency, 'abbreviation')) {
        return 'USD';
    }
    return get(currency, 'abbreviation', 'USD');
};

export const getCountryCurrencyLocale = () => {
    const currency = getCountryCurrency();
    if (!hasValue(currency, 'abbreviation')) {
        return 'en-US';
    }
    return get(currency, 'locale', 'en-US').replace(/_/g, '-');
};

export const getIsMexicanAccount = () => {
    const currency = getCountryCurrency();
    const iso = get(currency, 'abbreviation', 'USD');
    return iso === 'MXN';
};
