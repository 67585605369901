import { getInteractiveProposalSignature } from 'common/api/interactiveProposal';

import {
    FETCH_INTERACTIVE_PROPOSAL_SIGNATURE,
    FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
} from '../actionTypes';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch({ type: FETCH_INTERACTIVE_PROPOSAL_SIGNATURE });

        getInteractiveProposalSignature(id)
            .then((response) => {
                dispatch({
                    type: FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) =>
                dispatch({
                    type: FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
                    payload: error,
                })
            )
            .finally(resolve);
    });
