import { object, ref, string } from 'yup';

import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(
    ({ INVALID_NIP_TEXT, REQUIRED_TEXT, minimumNumberCharacters }) =>
        object().shape({
            accept_terms: string().required(REQUIRED_TEXT),
            nip: string()
                .oneOf([ref('currentNip')], INVALID_NIP_TEXT)
                .min(3, minimumNumberCharacters(8))
                .required(REQUIRED_TEXT)
                .nullable(),
        })
);
