import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as templateReducer } from 'sunwise-template-core';

import alerts from 'common/modules/alerts';
import lisaFiles from 'common/modules/lisaFiles';
import orm from 'common/orm';
import createReducer from 'common/orm/createReducer';

import creditBureau from './modules/creditBureau';
import interactiveProposal from './modules/interactiveProposal';
import interactiveProposalPreview from './modules/interactiveProposalPreview';

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        form: formReducer,
        entities: createReducer(orm),
        sunwiseTemplateCore: templateReducer,
        [alerts.NAME]: alerts.reducer,
        [creditBureau.NAME]: creditBureau.reducer,
        [interactiveProposal.NAME]: interactiveProposal.reducer,
        [interactiveProposalPreview.NAME]: interactiveProposalPreview.reducer,
        [lisaFiles.NAME]: lisaFiles.reducer,
    });
