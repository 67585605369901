import API from '../.';

export const authorization = (data, token) =>
    API.post('/api/v1/burocredito/authorization/', data, {
        headers: { Authorization: `JWT ${token}` },
    });
export const reject = (token) =>
    API.post('/api/v1/burocredito/rejecting/', null, {
        headers: { Authorization: `JWT ${token}` },
    });
