import i18next from 'i18next';

export const getTranslatedErrorMessage = (error) => {
    switch (error) {
        case 'Invalid NIP or data has expired.':
            return i18next.t('Invalid NIP or data has expired');
        default:
            return i18next.t('Invalid data');
    }
};
