import React from 'react';

import { styled } from '@mui/material/styles';
import { Box, Divider, Typography } from 'sunwise-ui';

const StyledFooter = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    padding: 1rem;
`;

const Footer = () => (
    <>
        <Divider />
        <StyledFooter>
            <Typography variant="caption" sx={{ color: '#848bab' }}>
                Sunpal ({process.env.REACT_APP_ENV}-
                {process.env.REACT_APP_VERSION})
            </Typography>
        </StyledFooter>
    </>
);

export default Footer;
