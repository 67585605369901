import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
} from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SignaturePad from 'signature_pad';
// import styled from 'styled-components';
import { Box } from 'sunwise-ui';

const Container = styled(Box)`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #fff;
    border: 1px solid #eef4fa;
    box-sizing: border-box;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px rgba(238, 244, 250, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
`;

const Detail = styled(Box)`
    position: absolute;
    bottom: -1px;
    height: 3px;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    background: #ffa200;
    border-radius: 3px 3px 0 0;
`;

const Canvas = styled('canvas')`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const SignatureView = ({
    disabled,
    initialData,
    myForwardedRef,
    onBeginStroke,
}) => {
    const containerRef = useRef(null);
    const canvasRef = useRef(null);
    const signaturePad = useRef(null);

    const resizeCanvas = (initialData) => {
        const canvas = canvasRef.current;
        const container = containerRef.current;
        const ratio = Math.max(window.devicePixelRatio || 1);
        canvas.width = container.offsetWidth * ratio;
        canvas.height = container.offsetHeight * ratio;
        canvas.getContext('2d').scale(ratio, ratio);

        signaturePad.current.clear();

        if (initialData) {
            signaturePad.current.fromData(initialData);
        }
    };

    const handleBeginStroke = () => {
        if (onBeginStroke) onBeginStroke();
    };

    useEffect(() => {
        signaturePad.current = new SignaturePad(canvasRef.current);
        const handleCanvasResize = () => {
            resizeCanvas(initialData);
        };
        resizeCanvas(initialData);
        window.removeEventListener('resize', handleCanvasResize);
        window.addEventListener('resize', handleCanvasResize);

        window.removeEventListener('beginStroke', handleBeginStroke);
        signaturePad.current.addEventListener('beginStroke', handleBeginStroke);

        return () => {
            window.removeEventListener('resize', handleCanvasResize);
            window.removeEventListener('beginStroke', handleBeginStroke);
        };
    }, [initialData]);

    useEffect(() => {
        if (disabled) {
            signaturePad.current.off();
        } else {
            signaturePad.current.on();
        }
    }, [disabled]);

    useImperativeHandle(myForwardedRef, () => ({
        getData() {
            return signaturePad.current && signaturePad.current.toData();
        },
        getDataURL() {
            return signaturePad.current && signaturePad.current.toDataURL();
        },
        clearSignature() {
            return signaturePad.current && signaturePad.current.clear();
        },
        isEmpty() {
            return signaturePad.current && signaturePad.current.isEmpty();
        },
    }));

    return (
        <Container className={disabled && 'disabled'} ref={containerRef}>
            <Canvas ref={canvasRef} />
            <Detail sx={{ backgroundColor: 'primary.main' }} />
        </Container>
    );
};

SignatureView.propTypes = {
    disabled: PropTypes.bool,
    height: PropTypes.number,
    initialData: PropTypes.array,
    myForwardedRef: PropTypes.object,
    onBeginStroke: PropTypes.func,
    width: PropTypes.number,
};

const SignatureViewForwared = forwardRef((props, ref) => (
    <SignatureView {...props} myForwardedRef={ref} />
));

SignatureViewForwared.displayName = 'SignatureViewForwared';

export default SignatureViewForwared;
