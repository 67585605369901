import { object, string } from 'yup';

import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(({ REQUIRED_TEXT, minimumNumberCharacters }) =>
    object().shape({
        authorize: string().required(REQUIRED_TEXT),
        nip: string()
            .min(3, minimumNumberCharacters(8))
            .required(REQUIRED_TEXT)
            .nullable(),
    })
);
