import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import Logo from './Logo';

const Wrapper = styled(Box)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 10000;
    & .loading-image {
        @keyframes rotate {
            0% {
                transform: rotate(0deg);
                opacity: 0;
            }
            50% {
                transform: rotate(180deg);
                opacity: 1;
            }
            100% {
                transform: rotate(360deg);
                opacity: 0;
            }
        }
        animation: rotate 2s linear infinite;
    }
`;

const MainLoading = ({ selectedTheme }) => {
    const [timerMs, setTimerMs] = useState(2350);
    const [showLoading, setShowLoading] = useState(true);
    let timer = null;

    useEffect(() => {
        timer = setTimeout(() => {
            setShowLoading(false);
        }, timerMs);

        return () => clearTimeout(timer);
    }, [timerMs]);

    useEffect(() => {
        if (showLoading) document.body.classList.add('overflow-hidden');
        else document.body.classList.remove('overflow-hidden');
    }, [showLoading]);

    return showLoading ? (
        <Wrapper
            sx={{
                backgroundColor:
                    selectedTheme === 'dark' ? '#000000' : '#002438',
            }}
        >
            <Logo handleUpdateTime={(time) => setTimerMs(time)} />
        </Wrapper>
    ) : null;
};

MainLoading.propTypes = {
    selectedTheme: PropTypes.string,
};

export default MainLoading;
