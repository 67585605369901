import get from 'lodash/get';

import { getElectricBills } from 'common/api/interactiveProposal';

import {
    FETCH_ELECTRIC_BILLS,
    FETCH_ELECTRIC_BILLS_FAILURE,
    FETCH_ELECTRIC_BILLS_SUCCESS,
} from '../actionTypes';
import { getElectricBillPerMonths } from '../helpers';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch({ type: FETCH_ELECTRIC_BILLS });

        getElectricBills(id)
            .then((response) => {
                const data = get(response, 'data.data', []);
                dispatch({
                    type: FETCH_ELECTRIC_BILLS_SUCCESS,
                    payload: getElectricBillPerMonths({ lines: data }),
                });
            })
            .catch((error) =>
                dispatch({
                    type: FETCH_ELECTRIC_BILLS_FAILURE,
                    payload: error,
                })
            )
            .finally(resolve);
    });
