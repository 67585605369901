import React from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { types } from 'sunwise-template-core';
import {
    Box,
    Card,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from 'sunwise-ui';

import { DATASHEET_PDF_TYPE } from '../constants';
import {
    getDatasheetLabel,
    getDatasheetsByType,
    getDatasheetTypes,
    getFileNameFromURL,
} from '../helpers';

import DocumentsPlaceholder from './DocumentsPlaceholder';

const StyledCardHeader = styled(Card.Header)`
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
        ),
        #eef4fa;
`;

const StyledCardBody = styled(Card.Body)`
    width: 100%;
    height: 250px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        background: transparent;
        height: 7px;
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.15);
        border-radius: 2px;
    }
`;

const DocumentsSection = ({
    datasheets,
    downloadDatasheet,
    fetchSmartDocumentContent,
    isLoading,
    loadingContext,
    renderPdfFile,
    setSelectedDocument,
    smartDocuments,
}) => {
    const { t } = useTranslation();

    const datasheetTypes = getDatasheetTypes(datasheets);

    if (isLoading) {
        return <DocumentsPlaceholder />;
    }

    return (
        <Box>
            <Grid container>
                {!isEmpty(smartDocuments) && (
                    <Grid item xs={18} md={isEmpty(datasheets) ? 18 : 9}>
                        <Card>
                            <StyledCardHeader>
                                <Typography variant="body2" fontWeight="bold">
                                    {t('Document', { count: 2 })}
                                </Typography>
                            </StyledCardHeader>
                            <StyledCardBody>
                                <List>
                                    {smartDocuments.map((document) => (
                                        <ListItem
                                            disablePadding
                                            key={`smart-document-${document.id}`}
                                        >
                                            <ListItemButton>
                                                <Tooltip title={document.name}>
                                                    <ListItemText
                                                        primary={document.name}
                                                        sx={{
                                                            '& .MuiTypography-root':
                                                                {
                                                                    overflow:
                                                                        'hidden',
                                                                    textAlign:
                                                                        'left',
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    width: 'calc(100% - 16px)',
                                                                },
                                                        }}
                                                    />
                                                </Tooltip>
                                                {document.has_content && (
                                                    <ListItemIcon
                                                        onClick={() =>
                                                            fetchSmartDocumentContent(
                                                                document.document_reference_id,
                                                                (data) =>
                                                                    setSelectedDocument(
                                                                        {
                                                                            type: types.SMART_DOCUMENTS_TYPE,
                                                                            data,
                                                                        }
                                                                    )
                                                            )
                                                        }
                                                    >
                                                        <VisibilityIcon />
                                                    </ListItemIcon>
                                                )}

                                                <ListItemIcon
                                                    onClick={() => {
                                                        renderPdfFile(
                                                            document.document_reference_id,
                                                            document.name,
                                                            () =>
                                                                loadingContext.openLoading(
                                                                    t(
                                                                        'Downloading PDF'
                                                                    ).concat(
                                                                        '...'
                                                                    )
                                                                ),
                                                            () =>
                                                                loadingContext.closeLoading(),
                                                            types.SMART_DOCUMENTS_TYPE
                                                        );
                                                    }}
                                                >
                                                    <DownloadIcon />
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </StyledCardBody>
                        </Card>
                    </Grid>
                )}
                {!isEmpty(datasheets) && (
                    <Grid item xs={18} md={isEmpty(smartDocuments) ? 18 : 9}>
                        <Card>
                            <StyledCardHeader>
                                <Typography variant="body2" fontWeight="bold">
                                    {t('Data sheet', { count: 2 })}
                                </Typography>
                            </StyledCardHeader>
                            <StyledCardBody>
                                {datasheetTypes.map((type) => (
                                    <List key={`datasheet-type-${type}`}>
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                            sx={{ p: '8px 16px' }}
                                        >
                                            {getDatasheetLabel(type)}
                                        </Typography>
                                        {getDatasheetsByType(
                                            datasheets,
                                            type
                                        ).map((datasheet) => (
                                            <ListItem
                                                disablePadding
                                                key={`datasheet-${datasheet.id}`}
                                            >
                                                <ListItemButton>
                                                    <ListItemText
                                                        primary={datasheet.name}
                                                    />
                                                    <ListItemIcon
                                                        onClick={() =>
                                                            setSelectedDocument(
                                                                {
                                                                    type: DATASHEET_PDF_TYPE,
                                                                    data: datasheet.archive,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        <VisibilityIcon />
                                                    </ListItemIcon>
                                                    <ListItemIcon
                                                        onClick={() =>
                                                            downloadDatasheet(
                                                                getFileNameFromURL(
                                                                    datasheet.archive
                                                                ),
                                                                (text) =>
                                                                    loadingContext.openLoading(
                                                                        text
                                                                    ),
                                                                () => {
                                                                    loadingContext.closeLoading();
                                                                }
                                                            )
                                                        }
                                                    >
                                                        <DownloadIcon />
                                                    </ListItemIcon>
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                ))}
                            </StyledCardBody>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

DocumentsSection.propTypes = {
    datasheets: PropTypes.array,
    downloadDatasheet: PropTypes.func,
    fetchSmartDocumentContent: PropTypes.func,
    handleClickDownload: PropTypes.func,
    isLoading: PropTypes.bool,
    loadingContext: PropTypes.object,
    renderPdfFile: PropTypes.func,
    setSelectedDocument: PropTypes.func,
    smartDocuments: PropTypes.array,
};

export default DocumentsSection;
