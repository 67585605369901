import React, { useEffect } from 'react';

import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Alert, Box, Button, Divider, Typography } from 'sunwise-ui';

import OpacityAnimation from 'common/components/animations/OpacityAnimation';
import ReduxFieldInput from 'common/components/form/ReduxFieldInput';

import logotipo from 'resources/logotipoSunpal.svg';

import * as actions from '../actions';
import { NAME } from '../constants';
import { getTranslatedErrorMessage } from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

const Loginform = ({
    handleSubmit,
    isLoadingLogin,
    login,
    loginErrors,
    reset,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    const handleOnSubmit = (values) => login(values);

    return (
        <Box
            display="flex"
            width="100%"
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <OpacityAnimation>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        maxWidth: '670px',
                        m: '0 auto',
                        px: 3,
                    }}
                >
                    <Box my={1} width="100%">
                        <img alt="Sunpal" src={logotipo} width="238" />
                    </Box>

                    <Box my={1} width="100%">
                        <Typography variant="h3">{t('Log in')}</Typography>
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <form onSubmit={handleSubmit(handleOnSubmit)}>
                        <Field
                            component={ReduxFieldInput}
                            fullWidth
                            icon={<PersonIcon fontSize="medium" />}
                            label={t('User')}
                            name="username"
                            type="username"
                            variant="standard"
                        />

                        <Field
                            component={ReduxFieldInput}
                            fullWidth
                            icon={<LockIcon fontSize="medium" />}
                            label="NIP"
                            name="password"
                            type="password"
                            variant="standard"
                        />

                        {loginErrors &&
                            loginErrors.map((error, index) => (
                                <Alert
                                    key={`error-login-${index}`}
                                    severity="warning"
                                >
                                    <small>{`*${getTranslatedErrorMessage(
                                        error
                                    )}`}</small>
                                </Alert>
                            ))}

                        <Button
                            disabled={isLoadingLogin}
                            fullWidth
                            type="submit"
                        >
                            {isLoadingLogin
                                ? t('Loading').concat('...')
                                : t('Log in')}
                        </Button>
                    </form>
                </Box>
            </OpacityAnimation>
        </Box>
    );
};

Loginform.propTypes = {
    handleSubmit: PropTypes.func,
    isLoadingLogin: PropTypes.bool,
    login: PropTypes.func,
    loginErrors: PropTypes.array,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isLoadingLogin: selectors.getIsLoadingLogin,
    loginErrors: selectors.getLoginErrors,
});

const mapDispatchToProps = (dispatch) => ({
    login: (data, onSuccessCallback) =>
        dispatch(actions.login(data, onSuccessCallback)),
    reset: () => dispatch(actions.resetLoginForm()),
});

const ReduxLoginForm = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(Loginform);

export default connect(mapStateToProps, mapDispatchToProps)(ReduxLoginForm);
