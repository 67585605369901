import { getTemplatecontent } from 'common/api/renderContent';

import {
    FETCH_FINANCING_TEMPLATE,
    FETCH_FINANCING_TEMPLATE_SUCCESS,
    FETCH_FINANCING_TEMPLATE_FAILURE,
} from '../actionTypes';

export default (id, initTemplate) => (dispatch) =>
    new Promise((resolve) => {
        dispatch({ type: FETCH_FINANCING_TEMPLATE });

        return getTemplatecontent(id)
            .then((response) => {
                dispatch({
                    type: FETCH_FINANCING_TEMPLATE_SUCCESS,
                    payload: response.data,
                });
                initTemplate(response.data, id);
            })
            .catch((error) =>
                dispatch({
                    type: FETCH_FINANCING_TEMPLATE_FAILURE,
                    payload: error,
                })
            )
            .finally(resolve);
    });
