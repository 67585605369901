import { RESET_SESSION } from './actionTypes';

const defaultUpdater = (session, action) => {
    session.sessionBoundModels.forEach((modelClass) => {
        if (typeof modelClass.reducer === 'function') {
            // This calls this.applyUpdate to update this.state
            modelClass.reducer(action, modelClass, session);
        }
    });
};

export default (orm, updater = defaultUpdater) =>
    (state, action) => {
        const ormState =
            action.type === RESET_SESSION
                ? orm.getEmptyState()
                : state || orm.getEmptyState();

        const session = orm.session(ormState);
        updater(session, action);
        return session.state;
    };
