import BaseModel from './BaseModel';
export default class Timeline extends BaseModel {}

Timeline.modelName = 'Timeline';
Timeline.prepareFields({
    eventsClosed: 'events_closed',
    id: 'id',
    isArchived: 'is_archived',
    name: 'name',
    status: 'status',
    totalTimelineEvents: 'total_timeline_events',
});
