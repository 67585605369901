import i18next from 'i18next';
import { types } from 'sunwise-template-core';

import { getPdfFile } from 'common/api/renderTemplate';
import showToast from 'common/utils/showToast';

import downloadFile from './downloadFile';
export default (
        templateFinishedId,
        renderName,
        openLoading,
        closeLoading,
        templateType = types.ONE_PROPOSAL_TYPE,
        quality = 2
    ) =>
    (dispatch) => {
        openLoading(i18next.t('Generating PDF').concat('...'));
        getPdfFile({
            quality,
            renderName,
            templateFinishedId,
            templateType,
        })
            .then((response) => {
                closeLoading();
                dispatch(downloadFile(renderName, response.data, 'pdf'));
                showToast({
                    body: 'Se ha descargado con éxito',
                });
            })
            .catch(() => {
                closeLoading();
                showToast({
                    type: 'danger',
                    body: i18next.t('An error occurred'),
                });
            });
    };
