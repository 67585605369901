import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)`
    width: 100%;
    padding-top: 24px;
    margin-top: 16px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        background: transparent;
        height: 7px;
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.15);
        border-radius: 2px;
    }
`;
