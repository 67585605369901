import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

import SunpalLogo from 'resources/lotties/SunpalLogo.json';

const LottieLogo = ({ handleUpdateTime }) => {
    const lottieRef = useRef(null);
    const [autoplay, setAutoplay] = useState(false);
    const [isStopped, setIsStopped] = useState(true);

    const getDuration = (totalFrames, frameRate) => {
        const extraMs = 50;
        const seconds = Math.floor(totalFrames / frameRate);
        return seconds * 1000 + extraMs;
    };

    useEffect(() => {
        setAutoplay(true);
        setIsStopped(false);
    }, []);

    useEffect(() => {
        if (lottieRef.current !== null) {
            const anim = lottieRef.current?.anim;
            const totalFrames = anim?.totalFrames;
            const frameRate = anim?.frameRate;
            const duration = getDuration(totalFrames, frameRate);
            if (handleUpdateTime) handleUpdateTime(duration);
            setTimeout(() => {
                setIsStopped(true);
            }, duration);
        }
    }, [lottieRef, handleUpdateTime]);

    const defaultOptions = {
        loop: true,
        autoplay: autoplay,
        animationData: SunpalLogo,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <Lottie
            height={150}
            isClickToPauseDisabled={true}
            isStopped={isStopped}
            options={defaultOptions}
            ref={lottieRef}
            width={150}
        />
    );
};

LottieLogo.propTypes = {
    selectedTheme: PropTypes.string,
    handleUpdateTime: PropTypes.func,
};

export default LottieLogo;
