import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TextField } from 'sunwise-ui';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin: 0 auto;
    max-width: 360px;
    width: 100%;
`;

const InstructionText = styled.div`
    color: #848bab;
    font-size: 12px;
    font-style: italic;
    line-height: 16px;
    margin-bottom: 2px;
    min-height: 16px;
`;

const TextCaptcha = ({ isValid, onValidate, textToValidate }) => {
    const { t } = useTranslation();

    const [text, setText] = useState('');

    useEffect(() => {
        if ((text === textToValidate) !== isValid) {
            onValidate(text === textToValidate);
        }
    }, [text]);

    return (
        <Wrapper>
            <InstructionText>
                {t(
                    'Type the word {{textToValidate}} to confirm the operation',
                    { textToValidate }
                )}
            </InstructionText>
            <TextField
                fullWidth
                value={text}
                onChange={(event) => setText(event.target.value)}
            />
        </Wrapper>
    );
};

TextCaptcha.propTypes = {
    isValid: PropTypes.bool,
    onValidate: PropTypes.func,
    textToValidate: PropTypes.string,
};

export default TextCaptcha;
