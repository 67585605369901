import * as selectors from '../selectors';

import close from './close';

export default (response) => (dispatch, getState) => {
    const state = getState();
    const onCancel = selectors.getOnCancel(state);

    dispatch(close());

    if (onCancel) {
        onCancel(response);
    }
};
