import React from 'react';

import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import styled from 'styled-components';
import { Box, InputAdornment, TextField } from 'sunwise-ui';

const TextCounter = styled.span`
    color: #9a9a9a;
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    height: 12px;
    letter-spacing: 0.25px;
    line-height: 12px;
    margin-top: 5px;
    text-align: end;
`;

const ReduxFieldInput = (props) => {
    const {
        append,
        appendIcon,
        caption,
        dataIntercomTarget,
        defaultBehevior,
        hasCounter,
        icon,
        input: { name, onBlur, onChange, onFocus, value },
        InputProps,
        maxLength,
        meta: { error, touched },
        onlyIntegerNumbers,
        onlyNumbers,
        prepend,
        prependIcon,
        prependSvgIcon,
        readOnly,
        type,
        ...rest
    } = props;

    const onChangeAction = (event, defaultBehevior, onlyNumbers) => {
        const text = event.target.value;

        if (onlyNumbers) {
            event.target.value = text.replace(/[A-Za-z]/gi, '');
            onChange(event);
            return;
        }

        if (onlyIntegerNumbers) {
            event.target.value = text.replace(/[A-Za-z$,.]/gi, '');
            onChange(event);
            return;
        }

        if (
            !(
                text.length > 1 &&
                text.charAt(0) === '0' &&
                text.charAt(1) === '0' &&
                !defaultBehevior
            )
        ) {
            if (type === 'number' && parseFloat(text) >= 1) {
                event.target.value = text.replace(/^0+/, '');
            }
        }
        onChange(event);
    };

    return (
        <>
            {name && <Element hidden name={`position-${name}`} />}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {icon && (
                    <Box py={2} mb={1}>
                        {icon}
                    </Box>
                )}
                <TextField
                    data-intercom-target={dataIntercomTarget}
                    error={Boolean(touched && (error ?? false))}
                    helperText={(Boolean(touched && error) && error) || caption}
                    InputProps={{
                        endAdornment: (appendIcon || append) && (
                            <InputAdornment position="end">
                                {appendIcon && <img alt="" src={appendIcon} />}
                                {append}
                            </InputAdornment>
                        ),
                        readOnly: readOnly,
                        startAdornment: (prepend ||
                            prependIcon ||
                            prependSvgIcon) && (
                            <InputAdornment position="start">
                                {prependIcon && <i className={prependIcon} />}
                                {prependSvgIcon && (
                                    <img alt="" src={prependSvgIcon} />
                                )}
                                {prepend}
                            </InputAdornment>
                        ),
                        ...InputProps,
                    }}
                    onBlur={onBlur}
                    onChange={(e) =>
                        onChangeAction(e, defaultBehevior, onlyNumbers)
                    }
                    onFocus={onFocus}
                    value={value}
                    {...rest}
                />
            </Box>
            {hasCounter && maxLength && (
                <TextCounter>
                    {value.length}/{maxLength}
                </TextCounter>
            )}
        </>
    );
};

ReduxFieldInput.defaultProps = {
    fullWidth: true,
    onlyIntegerNumbers: false,
    onlyNumbers: false,
};

ReduxFieldInput.propTypes = {
    append: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    appendIcon: PropTypes.string,
    caption: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.array]),
    dataIntercomTarget: PropTypes.string,
    defaultBehevior: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    hasCounter: PropTypes.bool,
    id: PropTypes.string,
    input: PropTypes.object,
    InputProps: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    meta: PropTypes.object,
    min: PropTypes.number,
    numberTypeOnBlurActionDisabled: PropTypes.bool,
    onlyIntegerNumbers: PropTypes.bool,
    onlyNumbers: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.object,
    prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    prependIcon: PropTypes.string,
    prependSvgIcon: PropTypes.string,
    readOnly: PropTypes.bool,
    size: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
};

export default ReduxFieldInput;
