import React from 'react';

import { Box, Typography } from 'sunwise-ui';

const Terms = () => {
    return (
        <Box>
            <Typography variant="body1">
                Envío y <strong>consulta</strong> de su información por parte de
                SUNWISE a personas o empresas que usted otorgue consentimiento
                para la obtención de productos y/o servicios financieros.
            </Typography>
            <Typography variant="body1">
                Los datos personales que recabamos son los siguientes:
            </Typography>
            <ul>
                <li>
                    Datos de identificación: nombre del contacto, fecha de
                    nacimiento, domicilio, número de teléfono, correo
                    electrónico, nombre de la empresa, etc.
                </li>
                <li>
                    Datos para facturación: RFC, domicilio, cuenta bancaria,
                    etc.
                </li>
                <li>
                    Datos de la empresa o persona que representa, relativos a la
                    capacidad y solvencia económica, por lo que respecta al
                    interés en los servicios directos e indirectos de SUNWISE.
                </li>
            </ul>
            <Typography variant="body1">
                Y serán obtenidos por medio de:
            </Typography>
            <ul>
                <li>
                    La información que usted ingrese en la página de Internet de
                    SUNWISE.
                </li>
                <li>
                    De los documentos que usted entregue o envíe a SUNWISE por
                    medio de la página de Internet durante la solicitud de
                    información sobre los servicios que presta SUNWISE.
                </li>
                <li>Consulta de Reportes de Buró de crédito.</li>
            </ul>
            <Typography variant="body1">
                Al aceptar los términos y condiciones estoy accediendo a ser
                usuario de los servicios de Sunwise y aprobar el uso de medios
                electrónicos para recabar mi autorización de reporte de crédito.
            </Typography>
        </Box>
    );
};

export default Terms;
