import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import alerts from 'common/modules/alerts';

const Wrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: rgb(248, 252, 255);
`;

const DefaultLayout = ({ children }) => (
    <>
        <Wrapper>{children}</Wrapper>
        <alerts.Container />
    </>
);

DefaultLayout.propTypes = { children: PropTypes.object };

export default DefaultLayout;
