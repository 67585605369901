import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)`
    width: 60px;
    height: 60px;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
        ),
        #eef4fa;
    border-radius: 60px;
    position: relative;
    img {
        min-width: 60px;
        height: 60px;
        background-size: cover;
        object-fit: cover;
        background-position: center;
        border-radius: 60px;
    }
`;
