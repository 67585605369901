import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import {
    CAN_VIEW,
    ALL_PERMISSIONS,
    CAN_CREATE_DELETE,
    CAN_CREATE_UPDATE,
    CAN_CREATE,
    CAN_DELETE,
    CAN_UPDATE_DELETE,
    CAN_UPDATE,
    DEFAULT_NO_PERMISSIONS,
} from 'common/constants/permissions';

import { getPermissionsDictionary, getUserPermits } from './session';

export const validateAccess = () => {
    return true;
};

export const validateAccessWithPermissions = () => {
    const userPermits = getUserPermits();
    let hasAccess = true;

    if (userPermits !== null) {
        return;
    }

    return hasAccess;
};

export const validateByPermissionCode = (permissionCode) => {
    const permissionsDictionary = getPermissionsDictionary();
    if (
        isEmpty(permissionsDictionary) ||
        ((!Array.isArray(permissionCode) || isEmpty(permissionCode)) &&
            typeof permissionCode !== 'number')
    ) {
        return DEFAULT_NO_PERMISSIONS;
    }
    if (Array.isArray(permissionCode)) {
        const permissionDictionary = permissionCode.reduce((acc, current) => {
            const permission = permissionsDictionary[current];
            if (!isUndefined(permission)) {
                return {
                    ...acc,
                    [current]: getCanActionsDictionary(
                        permissionsDictionary,
                        current
                    ),
                };
            } else {
                return {
                    ...acc,
                    [current]: DEFAULT_NO_PERMISSIONS,
                };
            }
        }, {});

        return {
            getPermissionsByCode: (code) => {
                const permission = permissionDictionary[code];
                if (!isUndefined(permission)) {
                    return permission;
                }

                return DEFAULT_NO_PERMISSIONS;
            },
        };
    }
    return getCanActionsDictionary(permissionsDictionary, permissionCode);
};

export const getCanActionsDictionary = (
    permissionsDictionary,
    permissionCode
) => {
    const permission = permissionsDictionary[permissionCode];
    if (!isUndefined(permission)) {
        const { is_enabled: isEnabled, permissions: permissionType } =
            permission;
        switch (permissionType) {
            case CAN_VIEW:
                return {
                    canCreate: false,
                    canDelete: false,
                    canUpdate: false,
                    canView: isEnabled,
                };
            case ALL_PERMISSIONS:
                return {
                    canCreate: true,
                    canDelete: true,
                    canUpdate: true,
                    canView: isEnabled,
                };
            case CAN_CREATE:
                return {
                    canCreate: true,
                    canDelete: false,
                    canUpdate: false,
                    canView: isEnabled,
                };
            case CAN_CREATE_DELETE:
                return {
                    canCreate: true,
                    canDelete: true,
                    canUpdate: false,
                    canView: isEnabled,
                };
            case CAN_CREATE_UPDATE:
                return {
                    canCreate: true,
                    canDelete: false,
                    canUpdate: true,
                    canView: isEnabled,
                };
            case CAN_DELETE:
                return {
                    canCreate: false,
                    canDelete: true,
                    canUpdate: false,
                    canView: isEnabled,
                };
            case CAN_UPDATE_DELETE:
                return {
                    canCreate: false,
                    canDelete: true,
                    canUpdate: true,
                    canView: isEnabled,
                };
            case CAN_UPDATE:
                return {
                    canCreate: false,
                    canDelete: false,
                    canUpdate: true,
                    canView: isEnabled,
                };
            default:
                return DEFAULT_NO_PERMISSIONS;
        }
    }
    return DEFAULT_NO_PERMISSIONS;
};

export const validateCanView = (permissionCode) => {
    const permissionsDictionary = getPermissionsDictionary();
    const permission = permissionsDictionary[permissionCode];
    if (permission) {
        const { is_enabled: isEnabled, permissions = null } = permission;
        return isEnabled && permissions !== null;
    }
    return false;
};
