export const NAME = 'interactive-proposal';

export const DATASHEET_PDF_TYPE = 3;
export const ELECTRIC_BILL_TYPE = 4;
export const FINANCING_TYPE = 5;
export const PDF_REAL_TEXT = 8;

export const PDF_LOW_QUALITY = 1;
export const PDF_MEDIUM_QUALITY = 2;
export const PDF_HIGHT_QUALITY = 3;
export const CSV_FILE = 4;
