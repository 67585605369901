import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, FormGroup, Grid, Switch } from 'sunwise-ui';

import ApprovalData from './ApprovalData';
import ApprovalForm from './ApprovalForm';

const ApprovalSection = ({
    approveEmail,
    deleteInteractiveProposalSignature,
    hasApproved,
    hasSignature,
    interactiveProposalSignatureData,
    offerId,
    saveInteractiveProposalSignature,
    setShowSignatureArea,
    showSignatureArea,
    updatedAt,
}) => {
    const { t } = useTranslation();

    if (hasApproved) {
        return (
            <Box>
                <ApprovalData
                    approveEmail={approveEmail}
                    hasApproved={hasApproved}
                    updatedAt={updatedAt}
                />
                <Grid container>
                    <Grid hidden={hasSignature} item xs={18}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showSignatureArea}
                                        onChange={(e) =>
                                            setShowSignatureArea(
                                                e.target.checked
                                            )
                                        }
                                    />
                                }
                                disabled={hasSignature}
                                label={t(
                                    'Add my digital signature to all documents submitted'
                                )}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <ApprovalForm
            deleteInteractiveProposalSignature={
                deleteInteractiveProposalSignature
            }
            interactiveProposalSignatureData={interactiveProposalSignatureData}
            offerId={offerId}
            saveInteractiveProposalSignature={saveInteractiveProposalSignature}
        />
    );
};

ApprovalSection.propTypes = {
    approveEmail: PropTypes.string,
    deleteInteractiveProposalSignature: PropTypes.func,
    hasApproved: PropTypes.bool,
    hasSignature: PropTypes.bool,
    interactiveProposalSignatureData: PropTypes.object,
    offerId: PropTypes.string,
    saveInteractiveProposalSignature: PropTypes.func,
    setShowSignatureArea: PropTypes.func,
    showSignatureArea: PropTypes.bool,
    updatedAt: PropTypes.string,
};

export default ApprovalSection;
