import React, { useRef, useState } from 'react';

import { styled } from '@mui/material/styles';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid } from 'sunwise-ui';

import SignatureView from 'common/components/SignatureView';

const SignatureContent = styled(Box)`
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 400px;
`;

const SignatureArea = ({
    deleteInteractiveProposalSignature,
    interactiveProposalSignatureData,
    isHidden,
    offerId,
    saveInteractiveProposalSignature,
}) => {
    const { t } = useTranslation();

    const inputRef = useRef(null);
    const signatureContetRef = useRef(null);
    const [hasStroke, setHasStroke] = useState(false);
    const [dataSignature, setDataSignature] = useState(null);
    const signatureId = get(interactiveProposalSignatureData, 'id', null);

    const signatureJson = get(
        interactiveProposalSignatureData,
        'signature',
        {}
    );

    const save = () => {
        const data = inputRef.current.getData();
        const image = inputRef.current.getDataURL();
        setDataSignature(data);
        saveInteractiveProposalSignature(offerId, {
            signature: data,
            base64: image,
        });
    };

    const reset = () => {
        inputRef.current.clearSignature();
        setHasStroke(false);
        setDataSignature(null);
        if (signatureId && !isEmpty(signatureJson)) {
            deleteInteractiveProposalSignature(offerId);
        }
    };

    const handleOnBeginStroke = () => setHasStroke(true);

    if (isHidden) return null;

    return (
        <Box mt={1}>
            <Grid container justifyContent="center">
                <Grid item xs={18}>
                    <SignatureContent ref={signatureContetRef}>
                        <SignatureView
                            disabled={!isEmpty(dataSignature)}
                            initialData={dataSignature}
                            onBeginStroke={handleOnBeginStroke}
                            ref={inputRef}
                        />
                    </SignatureContent>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={2}>
                <Grid
                    item
                    xs={18}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'center', md: 'right' },
                    }}
                >
                    <Button
                        color="error"
                        onClick={reset}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 2, md: 1 },
                            mr: { xs: 0, md: 2 },
                        }}
                        type="button"
                        variant="text"
                    >
                        {t('Delete')}
                    </Button>
                    <Button
                        disabled={!hasStroke}
                        onClick={save}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 1, md: 2 },
                            mb: { xs: 2, md: 0 },
                        }}
                        type="button"
                    >
                        {t('Accept')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

SignatureArea.propTypes = {
    deleteInteractiveProposalSignature: PropTypes.func,
    interactiveProposalSignatureData: PropTypes.object,
    isHidden: PropTypes.bool,
    offerId: PropTypes.string,
    saveInteractiveProposalSignature: PropTypes.func,
};

export default SignatureArea;
