import { styled } from '@mui/material/styles';
import { Chip } from 'sunwise-ui';

export default styled(Chip)`
    font-weight: bold;
    svg {
        margin-left: 5px;
        margin-right: -6px;
    }
    cursor: pointer;
`;
