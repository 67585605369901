import React, { useEffect } from 'react';

import LockIcon from '@mui/icons-material/Lock';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Box, Button, Divider, Typography } from 'sunwise-ui';

import OpacityAnimation from 'common/components/animations/OpacityAnimation';
import ReduxFieldCheck from 'common/components/form/ReduxFieldCheck';
import ReduxFieldInput from 'common/components/form/ReduxFieldInput';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validateTerms from '../validateTerms';

import Terms from './Terms';

const Wrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        background: transparent;
        height: 7px;
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.15);
        border-radius: 2px;
    }
`;

const TermsAndConditions = ({
    formErrors,
    formValues,
    handleAccepTerms,
    handleSubmit,
    initializeTermsForm,
    loginData,
    reset,
}) => {
    const { t } = useTranslation();
    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        if (!isEmpty(loginData)) {
            initializeTermsForm({ currentNip: loginData.nip });
        }
    }, [loginData]);

    return (
        <OpacityAnimation>
            <Wrapper px={3} py={2}>
                <Box display="flex" alignItems="center">
                    <Typography variant="h4">
                        {t('Terms and conditions')}
                    </Typography>
                    <Box sx={{ ml: 'auto' }}>
                        <Typography variant="h3">1/2</Typography>
                    </Box>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Terms />

                <Box my={2}>
                    <form onSubmit={handleSubmit(handleAccepTerms)}>
                        <Field
                            component={ReduxFieldCheck}
                            id="accept_terms"
                            label={t('I accept the terms and conditions')}
                            name="accept_terms"
                        />

                        <Field
                            component={ReduxFieldInput}
                            fullWidth
                            label="NIP"
                            name="nip"
                            icon={<LockIcon fontSize="medium" />}
                            type="password"
                            variant="standard"
                        />

                        <Box textAlign="right">
                            <Button
                                disabled={
                                    !formValues.accept_terms ||
                                    !isEmpty(formErrors)
                                }
                                sx={{
                                    mb: 2,
                                    width: { xs: '100%', md: 'auto' },
                                }}
                                type="submit"
                            >
                                {t('Continue')}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Wrapper>
        </OpacityAnimation>
    );
};

TermsAndConditions.propTypes = {
    formErrors: PropTypes.object,
    formValues: PropTypes.object,
    handleAccepTerms: PropTypes.func,
    handleSubmit: PropTypes.func,
    initializeTermsForm: PropTypes.func,
    loginData: PropTypes.object,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    formErrors: selectors.getTermsFormErrors,
    formValues: selectors.getTermsFormValues,
    initialValues: selectors.getTermsInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    handleAccepTerms: (values) => dispatch(actions.handleAccepTerms(values)),
    initializeTermsForm: (values) =>
        dispatch(actions.initializeTermsForm(values)),
    reset: () => dispatch(actions.resetTermsForm()),
});

const ReduxTermsAndContidions = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form-terms`,
    validate: validateTerms,
})(TermsAndConditions);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReduxTermsAndContidions);
