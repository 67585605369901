import { getInteractiveProposalContent } from 'common/api/interactiveProposal';

import {
    FETCH_INTERACTIVE_PROPOSAL_CONTENT,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS,
} from '../actionTypes';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch({ type: FETCH_INTERACTIVE_PROPOSAL_CONTENT });

        getInteractiveProposalContent(id)
            .then((response) => {
                dispatch({
                    type: FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) =>
                dispatch({
                    type: FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE,
                    payload: error,
                })
            )
            .finally(resolve);
    });
