import { ORM } from 'redux-orm';

import { modelPopulateByArray } from './helpers';
import InteractiveProject from './models/InteractiveProject';
import LinkedProposal from './models/LinkedProposal';
import Timeline from './models/Timeline';
import TimelineEvent from './models/TimelineEvent';

const orm = new ORM({
    stateSelector: (state) => state.entities,
});

orm.register(InteractiveProject, LinkedProposal, Timeline, TimelineEvent);

export { orm as default, modelPopulateByArray };
