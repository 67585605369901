import { getFormSyncErrors, getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

/* Login form */
export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

/* Terms and conditions form */
export const getTermsFormValues = createSelector(
    (state) => getFormValues(`${NAME}/form-terms`)(state),
    (values) => values || {}
);

export const getTermsInitialValues = createSelector(
    getModel,
    (model) => model.initialValuesTermsForm
);

export const getTermsFormErrors = createSelector(
    (state) => getFormSyncErrors(`${NAME}/form-terms`)(state),
    (errors) => errors || {}
);

/* Authorize form */
export const getAuthorizeFormValues = createSelector(
    (state) => getFormValues(`${NAME}/form-authorize`)(state),
    (values) => values || {}
);

export const getAuthorizeFormInitialValues = createSelector(
    getModel,
    (model) => model.initialValuesAuthorizeForm
);

export const getAuthorizeFormErrors = createSelector(
    (state) => getFormSyncErrors(`${NAME}/form-authorize`)(state),
    (errors) => errors || {}
);

/* Login */
export const getLogin = createSelector(getModel, (model) => model.login);

export const getLoginData = createSelector(getLogin, (model) => model.data);

export const getIsLoadingLogin = createSelector(
    getLogin,
    (model) => model.isLoading
);

export const getLoginErrors = createSelector(getLogin, (model) => model.errors);

/* Authorization */
export const getAuth = createSelector(getModel, (model) => model.authorization);

export const getAuthData = createSelector(getAuth, (model) => model.data);

export const getAuthisLoading = createSelector(
    getAuth,
    (model) => model.isLoading
);

export const getAuthErrors = createSelector(getAuth, (model) => model.errors);

/* Bureau Step */
export const getCurrentStep = createSelector(getModel, (model) => model.step);
