import React from 'react';

import PropTypes from 'prop-types';
import { types } from 'sunwise-template-core';

import PdfViewer from 'common/components/PdfViewer';

import { DATASHEET_PDF_TYPE } from '../constants';

import PageWrapper from './PageWrapper';
import TemplateSection from './TemplateSection';

const DisplayContent = ({
    isFetchingInteractiveProposalContent,
    isFetchingSmartDocumentContent,
    offerId,
    selectedDocument,
}) => {
    switch (selectedDocument?.type) {
        case types.SMART_DOCUMENTS_TYPE:
            return (
                <TemplateSection
                    isLoading={isFetchingSmartDocumentContent}
                    proposalId={offerId}
                    templateData={selectedDocument?.data}
                />
            );
        case DATASHEET_PDF_TYPE:
            return (
                <PageWrapper sx={{ height: { xs: 'auto', lg: '700px' } }}>
                    <PdfViewer
                        document={{
                            url: selectedDocument?.data,
                        }}
                    />
                </PageWrapper>
            );
        default:
            return (
                <TemplateSection
                    isLoading={isFetchingInteractiveProposalContent}
                    proposalId={offerId}
                    templateData={selectedDocument?.data}
                />
            );
    }
};

DisplayContent.propTypes = {
    isFetchingInteractiveProposalContent: PropTypes.bool,
    isFetchingSmartDocumentContent: PropTypes.bool,
    offerId: PropTypes.string,
    selectedDocument: PropTypes.object,
};

export default DisplayContent;
