import { object, string } from 'yup';

import schemaValidate from 'common/utils/schemaValidate';

const MIN_CHARACTERS = 7;

export default schemaValidate(({ REQUIRED_TEXT, minimumNumberCharacters }) =>
    object().shape({
        username: string().required(REQUIRED_TEXT).nullable(),
        password: string()
            .min(MIN_CHARACTERS, minimumNumberCharacters(MIN_CHARACTERS))
            .required(REQUIRED_TEXT)
            .nullable(),
    })
);
