import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import OpacityAnimation from 'common/components/animations/OpacityAnimation';

import icoSunpal from 'resources/logo-sunpal.svg';

const ResultMessage = () => {
    const { t } = useTranslation();
    return (
        <OpacityAnimation>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100vh',
                    textAlign: 'center',
                }}
            >
                <Box my={2}>
                    <img src={icoSunpal} width="150" />
                </Box>

                <Typography variant="h4">
                    {t('Thank you for completing the process')}.
                </Typography>
                <Typography variant="h4">
                    {t('Contact your installer for any clarification')}.
                </Typography>
            </Box>
        </OpacityAnimation>
    );
};

ResultMessage.propTypes = {};

export default ResultMessage;
