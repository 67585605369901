import React from 'react';

import PropTypes from 'prop-types';
import {
    FormControlLabel,
    FormHelperText,
    FormGroup,
    Checkbox,
} from 'sunwise-ui';

const ReduxFieldCheck = ({
    disabled,
    id,
    input: { onChange, value },
    label,
    meta: { error, touched },
    sxFormLabel,
}) => (
    <FormGroup>
        <FormControlLabel
            control={
                <Checkbox
                    checked={Boolean(value)}
                    disabled={disabled}
                    id={id}
                    onChange={onChange}
                />
            }
            label={label}
            sx={{ margin: !label ?? 0, ...sxFormLabel }}
        />
        {touched && error && (
            <FormHelperText type="error">{error}</FormHelperText>
        )}
    </FormGroup>
);

ReduxFieldCheck.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string,
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    meta: PropTypes.object,
    sxFormLabel: PropTypes.object,
};

export default ReduxFieldCheck;
