import React, { useEffect } from 'react';

import LockIcon from '@mui/icons-material/Lock';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Alert, Box, Button, Divider, Typography } from 'sunwise-ui';

import OpacityAnimation from 'common/components/animations/OpacityAnimation';
import ReduxFieldCheck from 'common/components/form/ReduxFieldCheck';
import ReduxFieldInput from 'common/components/form/ReduxFieldInput';

import * as actions from '../actions';
import { NAME } from '../constants';
import { getTranslatedErrorMessage } from '../helpers';
import * as selectors from '../selectors';
import validateAuthorize from '../validateAuthorize';

const Wrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        background: transparent;
        height: 7px;
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.15);
        border-radius: 2px;
    }
`;

const AuthorizeForm = ({
    authErrors,
    formErrors,
    formValues,
    handleAuth,
    handleSubmit,
    prepareReject,
    reset,
    token,
}) => {
    const { t } = useTranslation();
    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    const handleOnSubmit = (values) => handleAuth(values, token);
    const handleOnClickCancel = () => prepareReject(token);

    return (
        <OpacityAnimation>
            <Wrapper px={3} py={2}>
                <Box display="flex" alignItems="center">
                    <Typography variant="h4">
                        {t('Authorization to Sunwise')}
                    </Typography>
                    <Box sx={{ ml: 'auto' }}>
                        <Typography variant="h3">2/2</Typography>
                    </Box>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Typography variant="body1">
                    Autorizo expresamente a Sunwise S.A.P.I de C.V., para lleve
                    a cabo Investigaciones, sobre mi comportamiento Crediticio
                    en SIC que estime conveniente. Conozco la naturaleza y
                    alcance de la información que se solicitará, del uso que se
                    le dará y que se podrá realizar consultas periódicas de mi
                    historial crediticio, consiento que esta autorización tenga
                    vigencia de 3 años contados a partir de hoy, y en su caso
                    mientras mantengamos relación jurídica.
                </Typography>
                <Box my={2}>
                    <form onSubmit={handleSubmit(handleOnSubmit)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Field
                                component={ReduxFieldCheck}
                                id="authorize"
                                label={t('I authorize')}
                                name="authorize"
                            />
                        </Box>

                        <Field
                            component={ReduxFieldInput}
                            fullWidth
                            label="NIP"
                            name="nip"
                            icon={<LockIcon fontSize="medium" />}
                            type="password"
                            variant="standard"
                        />
                        {authErrors &&
                            authErrors.map((error, index) => (
                                <Alert
                                    key={`auth-error-${index}`}
                                    severity="warning"
                                >
                                    <small>{`*${getTranslatedErrorMessage(
                                        error
                                    )}`}</small>
                                </Alert>
                            ))}

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: { xs: 'center', md: 'end' },
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                disabled={
                                    !formValues.authorize ||
                                    !isEmpty(formErrors)
                                }
                                sx={{
                                    mb: 2,
                                    width: { xs: '100%', md: 'auto' },
                                    mr: { xs: 0, md: 2 },
                                }}
                                type="submit"
                            >
                                {t('Confirm')}
                            </Button>

                            <Button
                                onClick={handleOnClickCancel}
                                sx={{ width: { xs: '100%', md: 'auto' } }}
                                type="button"
                                variant="dafault"
                            >
                                {t("I don't authorize")}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Wrapper>
        </OpacityAnimation>
    );
};

AuthorizeForm.propTypes = {
    authErrors: PropTypes.array,
    formErrors: PropTypes.object,
    formValues: PropTypes.object,
    handleAuth: PropTypes.func,
    handleSubmit: PropTypes.func,
    prepareReject: PropTypes.func,
    reset: PropTypes.func,
    token: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    authErrors: selectors.getAuthErrors,
    formErrors: selectors.getAuthorizeFormErrors,
    formValues: selectors.getAuthorizeFormValues,
    initialValues: selectors.getAuthorizeFormInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    handleAuth: (values, token) => dispatch(actions.handleAuth(values, token)),
    prepareReject: (token) => dispatch(actions.prepareReject(token)),
    reset: () => dispatch(actions.resetAuthForm()),
});

const ReduxAuthorizeForm = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form-authorize`,
    validate: validateAuthorize,
})(AuthorizeForm);

export default connect(mapStateToProps, mapDispatchToProps)(ReduxAuthorizeForm);
