import get from 'lodash/get';

import { getSmarDocumentContent } from 'common/api/interactiveProposal';

import {
    FETCH_SMART_DOCUMENT_CONTENT,
    FETCH_SMART_DOCUMENT_CONTENT_FAILURE,
    FETCH_SMART_DOCUMENT_CONTENT_SUCCESS,
} from '../actionTypes';

export default (id, initTemplate) => (dispatch) =>
    new Promise((resolve) => {
        dispatch({ type: FETCH_SMART_DOCUMENT_CONTENT });

        getSmarDocumentContent(id)
            .then((response) => {
                const data = get(response, 'data', {});
                dispatch({
                    type: FETCH_SMART_DOCUMENT_CONTENT_SUCCESS,
                    payload: data,
                });
                const documentContent = get(data, 'content_str', '{}');
                initTemplate(JSON.parse(documentContent), id);
            })
            .catch((error) =>
                dispatch({
                    type: FETCH_SMART_DOCUMENT_CONTENT_FAILURE,
                    payload: error,
                })
            )
            .finally(resolve);
    });
