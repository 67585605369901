import { Model } from 'redux-orm';

import {
    getFieldsByDictionary,
    parseByDictionaryForCreate,
    parseByDictionaryForUpdate,
    getUnparsedDataByDictionary,
} from '../helpers';

class BaseModel extends Model {
    constructor({ _dictionary, ...rest }) {
        super(rest);
        this._fieldsDictionary = _dictionary;
    }

    //UPDATE BY DICTIONARY
    modify(props) {
        return this.update(
            parseByDictionaryForUpdate(props, this._fieldsDictionary)
        );
    }

    //GET UNPARSED DATA KEYS FOR BACK-END SERVICES
    toUnparsed() {
        return getUnparsedDataByDictionary(this.ref, this._fieldsDictionary);
    }

    // CREATE OR UPDATE BY DICTIONARY
    static generate(props) {
        this.upsert(parseByDictionaryForCreate(props, this.fieldsDictionary));
    }

    static prepareFields(dictionary) {
        this.fieldsDictionary = dictionary;
        this.fields = getFieldsByDictionary(this.fieldsDictionary);
    }
}
export default BaseModel;
