import React from 'react';

import PropTypes from 'prop-types';
import { Box, Grid, Skeleton } from 'sunwise-ui';

const DocumentsPlaceholder = () => {
    return (
        <Box>
            <Grid container>
                <Grid item xs={18} md={9}>
                    <Skeleton variant="rectangular" height={250} />
                </Grid>
                <Grid item xs={18} md={9}>
                    <Skeleton variant="rectangular" height={250} />
                </Grid>
            </Grid>
        </Box>
    );
};

DocumentsPlaceholder.propTypes = {
    isLoading: PropTypes.bool,
};

export default DocumentsPlaceholder;
