import { NAME } from './constants';

export const AUTH = `${NAME}/LOGIN`;
export const AUTH_FAILURE = `${NAME}/AUTH_FAILURE`;
export const AUTH_SUCCESS = `${NAME}/AUTH_SUCCESS`;

export const LOGIN = `${NAME}/LOGIN`;
export const LOGIN_FAILURE = `${NAME}/LOGIN_FAILURE`;
export const LOGIN_SUCCESS = `${NAME}/LOGIN_SUCCESS`;

export const INITIALIZE_TERMS_FORM = `${NAME}/INITIALIZE_TERMS_FORM`;

export const RESET = `${NAME}/RESET`;
export const RESET_AUTH_FORM = `${NAME}/RESET_AUTH_FORM`;
export const RESET_LOGIN_FORM = `${NAME}/RESET_LOGIN_FORM`;
export const RESET_TERMS_FORM = `${NAME}/RESET_TERMS_FORM`;

export const SET_STEP = `${NAME}/SET_STEP`;
