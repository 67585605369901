import React from 'react';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Switch } from 'sunwise-ui';

import ApprovalCard from './ApprovalCard';
import DataSheetsCard from './DataSheetsCard';
import DownloadCard from './DownloadCard';
import ElectricBillCard from './ElectricBillCard';
import FinancialCard from './FinancialCard';
import ProposalCard from './ProposalCard';
import SmartDocumentsCard from './SmartDocumentsCard';

const SupportMenuContent = ({
    datasheets,
    downloadFormat,
    electricBills,
    handleClickDatasheetItem,
    handleClickDocumentItem,
    handleClickDownload,
    handleClickElectricBillItem,
    handleClickOpenApprovalModal,
    hasApproved,
    hasSignature,
    interactiveProposalData,
    interactiveProposalSignatureData,
    isFetching,
    proposalId,
    selectedDocument,
    selectedTheme,
    setDownloadFormat,
    setTheme,
    smartDocuments,
}) => {
    const { t } = useTranslation();
    const handleOnSwitchTheme = (e) => {
        const theme = e.target.checked ? 'dark' : 'light';
        setTheme(theme);
    };
    const isDisabled = isFetching;

    return (
        <>
            <Box alignItems="center" display="flex" gap={1} mb={2}>
                <DarkModeIcon />
                {t('Dark mode')}{' '}
                <Switch
                    checked={selectedTheme === 'dark'}
                    onChange={handleOnSwitchTheme}
                />
            </Box>
            <ApprovalCard
                handleClickOpenApprovalModal={handleClickOpenApprovalModal}
                hasApproved={hasApproved}
                hasSignature={hasSignature}
                interactiveProposalSignatureData={
                    interactiveProposalSignatureData
                }
            />
            <DownloadCard
                downloadFormat={downloadFormat}
                handleClickDownload={handleClickDownload}
                selectedDocument={selectedDocument}
                setDownloadFormat={setDownloadFormat}
            />
            <ProposalCard
                handleClickDocumentItem={handleClickDocumentItem}
                isDisabled={isDisabled}
                proposalId={proposalId}
                proposalName={get(interactiveProposalData, 'proposal_name', '')}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />
            <ElectricBillCard
                electricBills={electricBills}
                handleClickElectricBillItem={handleClickElectricBillItem}
                isDisabled={isDisabled}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />
            <FinancialCard
                financing={get(interactiveProposalData, 'financing', {})}
                handleClickDatasheetItem={handleClickDatasheetItem}
                handleClickDocumentItem={handleClickDocumentItem}
                isDisabled={isDisabled}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />
            <SmartDocumentsCard
                handleClickDocumentItem={handleClickDocumentItem}
                isDisabled={isDisabled}
                selectedDocumentId={get(selectedDocument, 'id', null)}
                smartDocuments={smartDocuments}
            />
            <DataSheetsCard
                datasheets={datasheets}
                handleClickDatasheetItem={handleClickDatasheetItem}
                isDisabled={isDisabled}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />
        </>
    );
};

SupportMenuContent.propTypes = {
    datasheets: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    downloadFormat: PropTypes.number,
    electricBills: PropTypes.array,
    handleClickDatasheetItem: PropTypes.func,
    handleClickDocumentItem: PropTypes.func,
    handleClickDownload: PropTypes.func,
    handleClickElectricBillItem: PropTypes.func,
    handleClickOpenApprovalModal: PropTypes.func,
    hasApproved: PropTypes.bool,
    hasSignature: PropTypes.bool,
    interactiveProposalData: PropTypes.object,
    interactiveProposalSignatureData: PropTypes.object,
    isFetching: PropTypes.bool,
    proposalId: PropTypes.string,
    selectedDocument: PropTypes.object,
    selectedTheme: PropTypes.string,
    setDownloadFormat: PropTypes.func,
    setTheme: PropTypes.func,
    smartDocuments: PropTypes.array,
};

export default SupportMenuContent;
