import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useBreakpoint } from 'common/hooks';

import * as interactiveProposalSelectors from '../../../modules/interactiveProposalPreview/selectors';

import LottieLogo from './LottieLogo';

const Logo = ({ branding, isFetchingBranding, handleUpdateTime }) => {
    const breakpoint = useBreakpoint();
    const [logo, setLogo] = useState({});

    useEffect(() => {
        if (!isEmpty(branding))
            setLogo({
                isBranding: true,
                src: branding?.loading_image,
            });

        if (!isFetchingBranding && isEmpty(branding))
            setLogo({
                isBranding: false,
            });
    }, [branding, isFetchingBranding]);

    if (isEmpty(logo)) return null;

    return logo?.isBranding ? (
        <img
            alt="Logo"
            className="loading-image"
            src={logo?.src}
            style={{
                width: ['xs', 'sm'].includes(breakpoint) ? '35px' : '45px',
                maxWidth: 'initial',
            }}
        />
    ) : (
        <LottieLogo handleUpdateTime={handleUpdateTime} />
    );
};

Logo.propTypes = {
    branding: PropTypes.object,
    isFetchingBranding: PropTypes.bool,
    selectedTheme: PropTypes.string,
    handleUpdateTime: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    branding: interactiveProposalSelectors.getBrandingConfig,
    isFetchingBranding: interactiveProposalSelectors.getIsFetchingBranding,
});

export default connect(mapStateToProps, null)(Logo);
