import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AuthorizeForm from './components/AuthorizeForm';
import LoginForm from './components/LoginForm';
import ResultMessage from './components/ResultMessage';
import TermsAndConditions from './components/TermsAndConditions';
import Wrapper from './components/Wrapper';
import { BUREAU_STEPS } from './constants';
import * as selectors from './selectors';

const Container = ({ currentStep, loginData }) => {
    const renderContent = () => {
        switch (currentStep) {
            case BUREAU_STEPS.TERMS_AND_CONDITIONS:
                return <TermsAndConditions loginData={loginData} />;
            case BUREAU_STEPS.AUTHORIZATION:
                return <AuthorizeForm token={loginData?.token || null} />;
            case BUREAU_STEPS.COMPLETE:
                return <ResultMessage />;
            default:
                return <LoginForm />;
        }
    };
    return <Wrapper showBackground={!loginData}>{renderContent()}</Wrapper>;
};

const mapStateToProps = createStructuredSelector({
    currentStep: selectors.getCurrentStep,
    loginData: selectors.getLoginData,
});

Container.propTypes = {
    currentStep: PropTypes.string,
    loginData: PropTypes.object,
};

export default connect(mapStateToProps, null)(Container);
