import { getProposalAnalytics } from 'common/api/interactiveProposal';

import {
    SAVE_VISITS,
    SAVE_VISITS_FAILURE,
    SAVE_VISITS_SUCCESS,
} from '../actionTypes';

export default (id) => (dispatch) => {
    dispatch({ type: SAVE_VISITS });

    getProposalAnalytics(id)
        .then((response) => {
            dispatch({
                type: SAVE_VISITS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) =>
            dispatch({
                type: SAVE_VISITS_FAILURE,
                payload: error,
            })
        );
};
