import React, { useContext, useEffect, useRef, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { types } from 'sunwise-template-core';
import { editionLevels, withTemplateCore } from 'sunwise-template-core';
import { Grid } from 'sunwise-ui';

import SwipeableDrawer from 'common/components/SwipeableDrawer';
import Footer from 'common/layouts/DefaultLayout/Footer';
import * as lisaFileActions from 'common/modules/lisaFiles/actions';
import { LoadingContext } from 'common/utils/contexts';
import { getCurrentLanguage } from 'common/utils/helpers/multiregion';
import parseQueryString from 'common/utils/parseQueryString';

import * as actions from './actions';
import {
    AppBarMessage,
    ApprovalModal,
    ContactFooter,
    PreviewContent,
    NotFound,
    PageWrapper,
    ProposalResume,
    StyledSideBarColumn,
    SupportMenuContent,
} from './components';
import { PDF_MEDIUM_QUALITY } from './constants';
import {
    getIsLoadingContextMessage,
    getIsUpdatingContextMessage,
    handleClickDownloadBuild,
    handleClickDocumentItemBuild,
    handleClickElectricBillBuild,
    handleCloseDrawer,
} from './helpers';
import * as selectors from './selectors';

const Container = ({
    branding,
    deleteInteractiveProposalSignature,
    downloadDatasheet,
    downloadElectricBill,
    downloadFile,
    electricBills,
    fetchElectricBill,
    fetchFinancingTemplate,
    fetchSmartDocumentContent,
    handleClickOpenApprovalModal,
    hasInteractiveProposalError,
    initialFetching,
    initTemplate,
    interactiveProposalContentData,
    interactiveProposalData,
    interactiveProposalSignatureData,
    isApprovingInteractiveProposal,
    isDeletingInteractiveProposalSignature,
    isFetchingBranding,
    isFetchingElectricBills,
    isFetchingFinancingTemplate,
    isFetchingInteractiveProposal,
    isFetchingInteractiveProposalContent,
    isFetchingInteractiveProposalSignature,
    isFetchingSmartDocumentContent,
    isSavingInteractiveProposalSignature,
    location,
    match,
    renderPdfFile,
    reset,
    resetTemplate,
    saveInteractiveProposalSignature,
    selectedTheme,
    setTheme,
    showToolbarMessage,
    toogleToolbarMessage,
}) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [selectedDocument, setSelectedDocument] = useState({});
    const [showSignatureArea, setShowSignatureArea] = useState(false);
    const [downloadFormat, setDownloadFormat] = useState(
        parseInt(localStorage.getItem('downloadFormat')) || 2
    );
    const loadingContext = useContext(LoadingContext);
    const offerId = match.params.uid;
    const sideBarRef = useRef(null);
    const swipeableDrawerRef = useRef(null);

    const { search: queryParams = '' } = location;
    const { 'count-visit': countVisit } = parseQueryString(queryParams);

    useEffect(() => () => reset(), []);

    useEffect(() => {
        let _countVisit = true;
        if (countVisit === '0') _countVisit = false;
        initialFetching(offerId, initTemplate, _countVisit);
    }, [initTemplate, offerId]);

    useEffect(() => {
        getIsLoadingContextMessage({
            isFetchingBranding,
            isFetchingElectricBills,
            isFetchingFinancingTemplate,
            isFetchingInteractiveProposal,
            isFetchingInteractiveProposalContent,
            isFetchingInteractiveProposalSignature,
            isFetchingSmartDocumentContent,
            loadingContext,
        });
    }, [
        isFetchingBranding,
        isFetchingElectricBills,
        isFetchingFinancingTemplate,
        isFetchingInteractiveProposal,
        isFetchingInteractiveProposalContent,
        isFetchingInteractiveProposalSignature,
        isFetchingSmartDocumentContent,
    ]);

    useEffect(() => {
        getIsUpdatingContextMessage(
            isApprovingInteractiveProposal,
            isDeletingInteractiveProposalSignature,
            isSavingInteractiveProposalSignature,
            loadingContext
        );
    }, [
        isApprovingInteractiveProposal,
        isDeletingInteractiveProposalSignature,
        isSavingInteractiveProposalSignature,
    ]);

    const datasheets = get(interactiveProposalData, 'data_sheets', false);
    const hasApproved = get(interactiveProposalData, 'has_approbed', false);
    const hasPermalink = get(interactiveProposalData, 'has_permalink', false);
    const hasSignature = get(interactiveProposalData, 'has_signature', false);
    const smartDocuments = get(
        interactiveProposalData,
        'smart_documments',
        false
    );
    const templateContent = get(
        interactiveProposalContentData,
        'content_str',
        '{}'
    );

    useEffect(() => {
        if (!isEmpty(interactiveProposalData))
            setSelectedDocument({
                id: offerId,
                name: get(interactiveProposalData, 'proposal_name', ''),
                type: types.ONE_PROPOSAL_TYPE,
            });
    }, [interactiveProposalData]);

    const handleClickDatasheetItem = (datasheet) => {
        resetTemplate();
        setSelectedDocument(datasheet);
        setDownloadFormat(PDF_MEDIUM_QUALITY);
        handleCloseDrawer(swipeableDrawerRef);
    };

    const handleClickDownload = () =>
        handleClickDownloadBuild(
            downloadDatasheet,
            downloadElectricBill,
            downloadFile,
            loadingContext,
            renderPdfFile,
            selectedDocument,
            downloadFormat
        );

    const handleClickDocumentItem = (document) =>
        handleClickDocumentItemBuild(
            document,
            fetchSmartDocumentContent,
            fetchFinancingTemplate,
            initTemplate,
            resetTemplate,
            selectedDocument,
            setDownloadFormat,
            setSelectedDocument,
            swipeableDrawerRef,
            templateContent
        );

    const handleClickElectricBillItem = (document) =>
        handleClickElectricBillBuild(
            document,
            fetchElectricBill,
            loadingContext,
            setDownloadFormat,
            setSelectedDocument,
            swipeableDrawerRef
        );

    if (hasInteractiveProposalError || !hasPermalink)
        return <NotFound isLoading={isFetchingInteractiveProposal} />;

    return (
        <>
            <AppBarMessage
                showToolbarMessage={showToolbarMessage}
                toogleToolbarMessage={toogleToolbarMessage}
            />
            <Grid container spacing={0}>
                <Grid
                    item
                    lg={14}
                    md={12}
                    sx={{
                        height: {
                            md: '100vh',
                            xs: 'calc(100vh - 53px)',
                        },
                    }}
                    xs={18}
                >
                    <ProposalResume
                        branding={branding}
                        documentType={selectedDocument?.type || 0}
                        handleClickOpenApprovalModal={
                            handleClickOpenApprovalModal
                        }
                        hasApproved={hasApproved}
                        interactiveProposalData={interactiveProposalData}
                        interactiveProposalSignatureData={
                            interactiveProposalSignatureData
                        }
                        isLoading={isFetchingInteractiveProposal}
                        loadingContext={loadingContext}
                        offerId={offerId}
                        renderPdfFile={renderPdfFile}
                        setSelectedDocument={setSelectedDocument}
                        templateContent={templateContent}
                    />
                    <PageWrapper
                        sx={{
                            height: {
                                md: 'calc(100% - 94px)',
                                xs: 'calc(100% - 130px)',
                            },
                        }}
                    >
                        <PreviewContent
                            isFetchingFinancingTemplate={
                                isFetchingFinancingTemplate
                            }
                            isFetchingInteractiveProposalContent={
                                isFetchingInteractiveProposalContent
                            }
                            isFetchingSmartDocumentContent={
                                isFetchingSmartDocumentContent
                            }
                            selectedDocument={selectedDocument}
                        />
                        <ContactFooter
                            branding={branding}
                            interactiveProposalData={interactiveProposalData}
                        />
                        <Footer />
                    </PageWrapper>
                </Grid>
                <StyledSideBarColumn
                    item
                    lg={4}
                    md={6}
                    ref={sideBarRef}
                    sx={{
                        backgroundColor: isDarkMode ? '#000' : '#fff',
                        borderLeft: isDarkMode
                            ? '1px solid rgba(145, 158, 171, 0.24)'
                            : '0',
                        display: { xs: 'none', md: 'block' },
                        height: {
                            md: '100vh',
                            xs: 'calc(100vh - 53px)',
                        },
                    }}
                >
                    <SupportMenuContent
                        datasheets={datasheets}
                        downloadFormat={downloadFormat}
                        electricBills={electricBills}
                        handleClickDatasheetItem={handleClickDatasheetItem}
                        handleClickDocumentItem={handleClickDocumentItem}
                        handleClickDownload={handleClickDownload}
                        handleClickElectricBillItem={
                            handleClickElectricBillItem
                        }
                        handleClickOpenApprovalModal={
                            handleClickOpenApprovalModal
                        }
                        hasApproved={hasApproved}
                        hasSignature={hasSignature}
                        interactiveProposalData={interactiveProposalData}
                        interactiveProposalSignatureData={
                            interactiveProposalSignatureData
                        }
                        isFetching={
                            isFetchingBranding ||
                            isFetchingElectricBills ||
                            isFetchingFinancingTemplate ||
                            isFetchingInteractiveProposal ||
                            isFetchingInteractiveProposalContent ||
                            isFetchingInteractiveProposalSignature ||
                            isFetchingSmartDocumentContent
                        }
                        proposalId={offerId}
                        selectedDocument={selectedDocument}
                        selectedTheme={selectedTheme}
                        setDownloadFormat={setDownloadFormat}
                        setTheme={setTheme}
                        smartDocuments={smartDocuments}
                    />
                </StyledSideBarColumn>
            </Grid>
            <SwipeableDrawer
                ref={swipeableDrawerRef}
                sxWrapper={{ height: 'calc(100vh - 100px)' }}
            >
                <SupportMenuContent
                    datasheets={datasheets}
                    downloadFormat={downloadFormat}
                    electricBills={electricBills}
                    handleClickDatasheetItem={handleClickDatasheetItem}
                    handleClickDocumentItem={handleClickDocumentItem}
                    handleClickDownload={handleClickDownload}
                    handleClickElectricBillItem={handleClickElectricBillItem}
                    handleClickOpenApprovalModal={handleClickOpenApprovalModal}
                    hasApproved={hasApproved}
                    hasSignature={hasSignature}
                    interactiveProposalData={interactiveProposalData}
                    interactiveProposalSignatureData={
                        interactiveProposalSignatureData
                    }
                    isFetching={
                        isFetchingBranding ||
                        isFetchingElectricBills ||
                        isFetchingFinancingTemplate ||
                        isFetchingInteractiveProposal ||
                        isFetchingInteractiveProposalContent ||
                        isFetchingInteractiveProposalSignature ||
                        isFetchingSmartDocumentContent
                    }
                    proposalId={offerId}
                    selectedDocument={selectedDocument}
                    selectedTheme={selectedTheme}
                    setDownloadFormat={setDownloadFormat}
                    setTheme={setTheme}
                    smartDocuments={smartDocuments}
                />
            </SwipeableDrawer>
            <ApprovalModal
                deleteInteractiveProposalSignature={
                    deleteInteractiveProposalSignature
                }
                hasApproved={hasApproved}
                hasSignature={hasSignature}
                interactiveProposalSignatureData={
                    interactiveProposalSignatureData
                }
                offerId={offerId}
                saveInteractiveProposalSignature={
                    saveInteractiveProposalSignature
                }
                setShowSignatureArea={setShowSignatureArea}
                showSignatureArea={showSignatureArea}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    branding: selectors.getBrandingConfig,
    electricBills: selectors.getElectricBillsData,
    hasInteractiveProposalError: selectors.getHasInteractiveProposalError,
    interactiveProposalContentData: selectors.getInteractiveProposalContentData,
    interactiveProposalData: selectors.getInteractiveProposalData,
    interactiveProposalSignatureData:
        selectors.getInteractiveProposalSignatureData,
    isApprovingInteractiveProposal: selectors.getIsApprovingInteractiveProposal,
    isDeletingInteractiveProposalSignature:
        selectors.getIsDeletingInteractiveProposalSignature,
    isFetchingBranding: selectors.getIsFetchingBranding,
    isFetchingElectricBills: selectors.getIsFetchingElectricBills,
    isFetchingFinancingTemplate: selectors.getIsFetchingFinancingTemplate,
    isFetchingInteractiveProposal: selectors.getIsFetchingInteractiveProposal,
    isFetchingInteractiveProposalContent:
        selectors.getIsFetchingInteractiveProposalContent,
    isFetchingInteractiveProposalSignature:
        selectors.getIsFetchingInteractiveProposalSignature,
    isFetchingSmartDocumentContent: selectors.getIsFetchingSmartDocumentContent,
    isSavingInteractiveProposalSignature:
        selectors.getIsSavingInteractiveProposalSignature,
    selectedTheme: selectors.getTheme,
    showToolbarMessage: selectors.getShowToolbarMessage,
});

const mapDispatchToProps = (dispatch) => ({
    deleteInteractiveProposalSignature: (offerId) =>
        dispatch(actions.deleteInteractiveProposalSignature(offerId)),
    downloadDatasheet: (datasheetName, openLoading, closeLoading) =>
        dispatch(
            actions.downloadDatasheet(datasheetName, openLoading, closeLoading)
        ),
    downloadElectricBill: (archive, fileName, loadingContext, name, urlType) =>
        dispatch(
            actions.downloadElectricBill(
                archive,
                fileName,
                loadingContext,
                name,
                urlType
            )
        ),
    downloadFile: (name, url, type) =>
        dispatch(actions.downloadFile(name, url, type)),
    fetchElectricBill: (file_name, onSuccess) =>
        dispatch(lisaFileActions.getFile(file_name, onSuccess)),
    fetchFinancingTemplate: (id, initTemplate) =>
        dispatch(actions.fetchFinancingTemplateContent(id, initTemplate)),
    fetchSmartDocumentContent: (id, initTemplate) =>
        dispatch(actions.fetchSmartDocumentContent(id, initTemplate)),
    handleClickOpenApprovalModal: () =>
        dispatch(actions.openApprovalModal(true)),
    initialFetching: (offerId, initTemplate, countVisit) =>
        dispatch(actions.initialFetching(offerId, initTemplate, countVisit)),
    renderPdfFile: (
        templateFinishedId,
        templateName,
        openLoading,
        closeLoading,
        templateType,
        downloadFormat
    ) =>
        dispatch(
            actions.renderPdfFile(
                templateFinishedId,
                templateName,
                openLoading,
                closeLoading,
                templateType,
                downloadFormat
            )
        ),
    reset: () => dispatch(actions.reset()),
    saveInteractiveProposalSignature: (offerId, values) =>
        dispatch(actions.saveInteractiveProposalSignature(offerId, values)),
    setTheme: (value) => dispatch(actions.setTheme(value)),
    toogleToolbarMessage: (value) =>
        dispatch(actions.toogleToolbarMessage(value)),
});

Container.propTypes = {
    branding: PropTypes.object,
    deleteInteractiveProposalSignature: PropTypes.func,
    downloadDatasheet: PropTypes.func,
    downloadElectricBill: PropTypes.func,
    downloadFile: PropTypes.func,
    electricBills: PropTypes.array,
    fetchElectricBill: PropTypes.func,
    fetchFinancingTemplate: PropTypes.func,
    fetchSmartDocumentContent: PropTypes.func,
    handleClickOpenApprovalModal: PropTypes.func,
    hasInteractiveProposalError: PropTypes.bool,
    initialFetching: PropTypes.func,
    initTemplate: PropTypes.func,
    interactiveProposalContentData: PropTypes.object,
    interactiveProposalData: PropTypes.object,
    interactiveProposalSignatureData: PropTypes.object,
    isApprovingInteractiveProposal: PropTypes.bool,
    isDeletingInteractiveProposalSignature: PropTypes.bool,
    isFetchingBranding: PropTypes.bool,
    isFetchingElectricBills: PropTypes.bool,
    isFetchingFinancingTemplate: PropTypes.bool,
    isFetchingInteractiveProposal: PropTypes.bool,
    isFetchingInteractiveProposalContent: PropTypes.bool,
    isFetchingInteractiveProposalSignature: PropTypes.bool,
    isFetchingSmartDocumentContent: PropTypes.bool,
    isSavingInteractiveProposalSignature: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    renderPdfFile: PropTypes.func,
    reset: PropTypes.func,
    resetTemplate: PropTypes.func,
    saveInteractiveProposalSignature: PropTypes.func,
    selectedTheme: PropTypes.string,
    setTheme: PropTypes.func,
    showToolbarMessage: PropTypes.bool,
    toogleToolbarMessage: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withTemplateCore(() => ({
        application: 'sunpal',
        baseUrl: process.env.REACT_APP_API_URL,
        editionMode: editionLevels.NO_EDITION_MODE,
        froalaLicenseKey: process.env.REACT_APP_FROALA_LICENSE,
        googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        language: getCurrentLanguage(),
    }))
)(Container);
