import { saveInteractiveProposalSignature } from 'common/api/interactiveProposal';

import {
    SAVE_INTERACTIVE_PROPOSAL_SIGNATURE,
    SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
    SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
} from '../actionTypes';

import fetchInteractiveProposal from './fetchInteractiveProposal';
import fetchInteractiveProposalSignature from './fetchInteractiveProposalSignature';
import toogleToolbarMessage from './toogleToolbarMessage';

export default (offerId, values) => (dispatch) => {
    dispatch({ type: SAVE_INTERACTIVE_PROPOSAL_SIGNATURE });

    saveInteractiveProposalSignature(offerId, values)
        .then((response) => {
            dispatch({
                type: SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
                payload: response.data.data,
            });
            dispatch(toogleToolbarMessage(true));
            dispatch(fetchInteractiveProposal(offerId));
            dispatch(fetchInteractiveProposalSignature(offerId));
        })
        .catch((error) => {
            dispatch({
                type: SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
                payload: error,
            });
        });
};
