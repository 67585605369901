import styled from 'styled-components';

import fondoLogin from 'resources/fondoLogin.png';

export default styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    ${({ showBackground }) =>
        showBackground && `background-image: url(${fondoLogin});`};
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;
