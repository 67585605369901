import React, { useContext, useEffect, useState } from 'react';

import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { types } from 'sunwise-template-core';
import { Container as MaterialContainer } from 'sunwise-ui';

import AccordionSection from 'common/components/accordions/AccordionSection';
import Footer from 'common/layouts/DefaultLayout/Footer';
import { LoadingContext } from 'common/utils/contexts';

import * as actions from './actions';
import {
    AppBarMessage,
    ApprovalSection,
    CustomerSignature,
    DisplayContent,
    DocumentsSection,
    ContactFooter,
    NotFound,
    ProposalResume,
    SignatureArea,
} from './components';
import * as selectors from './selectors';

const Container = ({
    deleteInteractiveProposalSignature,
    downloadDatasheet,
    fetchSmartDocumentContent,
    hasInteractiveProposalError,
    initialFetching,
    interactiveProposalContentData,
    interactiveProposalData,
    interactiveProposalSignatureData,
    isApprovingInteractiveProposal,
    isDeletingInteractiveProposalSignature,
    isFetchingInteractiveProposal,
    isFetchingInteractiveProposalContent,
    isFetchingInteractiveProposalSignature,
    isFetchingSmartDocumentContent,
    isSavingInteractiveProposalSignature,
    match,
    renderPdfFile,
    reset,
    saveInteractiveProposalSignature,
    showToolbarMessage,
    toogleToolbarMessage,
}) => {
    const { t } = useTranslation();
    const [selectedDocument, setSelectedDocument] = useState({});
    const [showSignatureArea, setShowSignatureArea] = useState(false);
    const loadingContext = useContext(LoadingContext);
    const offerId = match.params.uid;

    useEffect(() => () => reset(), []);

    useEffect(() => {
        initialFetching(offerId);
    }, [offerId]);

    useEffect(() => {
        if (!isEmpty(loadingContext)) {
            if (
                isFetchingInteractiveProposal ||
                isFetchingInteractiveProposalSignature
            ) {
                loadingContext.openLoading(t('Loading').concat('...'));
            } else {
                loadingContext.closeLoading();
            }
        }
    }, [isFetchingInteractiveProposal, isFetchingInteractiveProposalSignature]);

    useEffect(() => {
        if (!isEmpty(loadingContext)) {
            if (
                isApprovingInteractiveProposal ||
                isDeletingInteractiveProposalSignature ||
                isSavingInteractiveProposalSignature
            ) {
                loadingContext.openLoading(t('Saving').concat('...'));
            } else {
                loadingContext.closeLoading();
            }
        }
    }, [
        isApprovingInteractiveProposal,
        isDeletingInteractiveProposalSignature,
        isSavingInteractiveProposalSignature,
    ]);

    const datasheets = get(interactiveProposalData, 'data_sheets', false);
    const hasApproved = get(interactiveProposalData, 'has_approbed', false);
    const hasPermalink = get(interactiveProposalData, 'has_permalink', false);
    const hasSignature = get(interactiveProposalData, 'has_signature', false);
    const smartDocuments = get(
        interactiveProposalData,
        'smart_documments',
        false
    );
    const templateContent = get(
        interactiveProposalContentData,
        'content_str',
        '{}'
    );

    useEffect(() => {
        if (templateContent)
            setSelectedDocument({
                type: types.ONE_PROPOSAL_TYPE,
                data: JSON.parse(templateContent),
            });
    }, [templateContent]);

    if (hasInteractiveProposalError || !hasPermalink) {
        return <NotFound isLoading={isFetchingInteractiveProposal} />;
    }

    return (
        <>
            <AppBarMessage
                showToolbarMessage={showToolbarMessage}
                toogleToolbarMessage={toogleToolbarMessage}
            />
            <ProposalResume
                documentType={selectedDocument?.type || 0}
                interactiveProposalData={interactiveProposalData}
                isLoading={isFetchingInteractiveProposal}
                loadingContext={loadingContext}
                offerId={offerId}
                renderPdfFile={renderPdfFile}
                setSelectedDocument={setSelectedDocument}
                templateContent={templateContent}
            />
            <MaterialContainer>
                <DisplayContent
                    isFetchingInteractiveProposalContent={
                        isFetchingInteractiveProposalContent
                    }
                    isFetchingSmartDocumentContent={
                        isFetchingSmartDocumentContent
                    }
                    offerId={offerId}
                    selectedDocument={selectedDocument}
                />
                <AccordionSection
                    defaultExpanded
                    isHidden={isEmpty(datasheets) && isEmpty(smartDocuments)}
                    title={t('Attached', { count: 2 })}
                    variantTitle="h4"
                >
                    <DocumentsSection
                        datasheets={datasheets}
                        downloadDatasheet={downloadDatasheet}
                        fetchSmartDocumentContent={fetchSmartDocumentContent}
                        isLoading={isFetchingInteractiveProposal}
                        loadingContext={loadingContext}
                        renderPdfFile={renderPdfFile}
                        setSelectedDocument={setSelectedDocument}
                        smartDocuments={smartDocuments}
                    />
                </AccordionSection>
                <AccordionSection
                    defaultExpanded
                    title={t('Approval')}
                    variantTitle="h4"
                >
                    <ApprovalSection
                        approveEmail={get(
                            interactiveProposalSignatureData,
                            'email',
                            ''
                        )}
                        deleteInteractiveProposalSignature={
                            deleteInteractiveProposalSignature
                        }
                        hasApproved={hasApproved}
                        hasSignature={hasSignature}
                        interactiveProposalSignatureData={
                            interactiveProposalSignatureData
                        }
                        offerId={offerId}
                        saveInteractiveProposalSignature={
                            saveInteractiveProposalSignature
                        }
                        setShowSignatureArea={setShowSignatureArea}
                        showSignatureArea={showSignatureArea}
                        updatedAt={get(
                            interactiveProposalSignatureData,
                            'updated',
                            ''
                        )}
                    />
                    <CustomerSignature
                        hasSignature={hasSignature}
                        signatureArchive={get(
                            interactiveProposalSignatureData,
                            'signature_archive',
                            ''
                        )}
                        signatureUpdated={get(
                            interactiveProposalSignatureData,
                            'updated',
                            ''
                        )}
                    />
                    <SignatureArea
                        deleteInteractiveProposalSignature={
                            deleteInteractiveProposalSignature
                        }
                        interactiveProposalSignatureData={
                            interactiveProposalSignatureData
                        }
                        isHidden={!showSignatureArea || hasSignature}
                        offerId={offerId}
                        saveInteractiveProposalSignature={
                            saveInteractiveProposalSignature
                        }
                    />
                </AccordionSection>
            </MaterialContainer>
            <ContactFooter interactiveProposalData={interactiveProposalData} />
            <Footer />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    hasInteractiveProposalError: selectors.getHasInteractiveProposalError,
    interactiveProposalContentData: selectors.getInteractiveProposalContentData,
    interactiveProposalData: selectors.getInteractiveProposalData,
    interactiveProposalSignatureData:
        selectors.getInteractiveProposalSignatureData,
    isApprovingInteractiveProposal: selectors.getIsApprovingInteractiveProposal,
    isDeletingInteractiveProposalSignature:
        selectors.getIsDeletingInteractiveProposalSignature,
    isFetchingInteractiveProposal: selectors.getIsFetchingInteractiveProposal,
    isFetchingInteractiveProposalContent:
        selectors.getIsFetchingInteractiveProposalContent,
    isFetchingInteractiveProposalSignature:
        selectors.getIsFetchingInteractiveProposalSignature,
    isFetchingSmartDocumentContent: selectors.getIsFetchingSmartDocumentContent,
    isSavingInteractiveProposalSignature:
        selectors.getIsSavingInteractiveProposalSignature,
    showToolbarMessage: selectors.getShowToolbarMessage,
});

const mapDispatchToProps = (dispatch) => ({
    deleteInteractiveProposalSignature: (offerId) =>
        dispatch(actions.deleteInteractiveProposalSignature(offerId)),
    downloadDatasheet: (datasheetName, openLoading, closeLoading) =>
        dispatch(
            actions.downloadDatasheet(datasheetName, openLoading, closeLoading)
        ),
    fetchSmartDocumentContent: (documentId, successCallback) =>
        dispatch(
            actions.fetchSmartDocumentContent(documentId, successCallback)
        ),
    initialFetching: (offerId) => dispatch(actions.initialFetching(offerId)),
    renderPdfFile: (
        templateFinishedId,
        templateName,
        openLoading,
        closeLoading,
        templateType
    ) =>
        dispatch(
            actions.renderPdfFile(
                templateFinishedId,
                templateName,
                openLoading,
                closeLoading,
                templateType
            )
        ),
    reset: () => dispatch(actions.reset()),
    saveInteractiveProposalSignature: (offerId, values) =>
        dispatch(actions.saveInteractiveProposalSignature(offerId, values)),
    toogleToolbarMessage: (value) =>
        dispatch(actions.toogleToolbarMessage(value)),
});

Container.propTypes = {
    deleteInteractiveProposalSignature: PropTypes.func,
    downloadDatasheet: PropTypes.func,
    fetchSmartDocumentContent: PropTypes.func,
    hasInteractiveProposalError: PropTypes.bool,
    initialFetching: PropTypes.func,
    interactiveProposalContentData: PropTypes.object,
    interactiveProposalData: PropTypes.object,
    interactiveProposalSignatureData: PropTypes.object,
    isApprovingInteractiveProposal: PropTypes.bool,
    isDeletingInteractiveProposalSignature: PropTypes.bool,
    isFetchingInteractiveProposal: PropTypes.bool,
    isFetchingInteractiveProposalContent: PropTypes.bool,
    isFetchingInteractiveProposalSignature: PropTypes.bool,
    isFetchingSmartDocumentContent: PropTypes.bool,
    isSavingInteractiveProposalSignature: PropTypes.bool,
    match: PropTypes.object,
    renderPdfFile: PropTypes.func,
    reset: PropTypes.func,
    saveInteractiveProposalSignature: PropTypes.func,
    showToolbarMessage: PropTypes.bool,
    toogleToolbarMessage: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(Container);
