import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)`
    width: 50px;
    height: 50px;
    position: relative;
    img {
        min-width: 50px;
        height: 50px;
        background-size: contain;
        object-fit: contain;
        background-position: center;
    }
`;
