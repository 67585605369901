import API from '../sunpalAPI';
const ENTITY = 'proposals';

export const approveInteractiveProposal = (data) =>
    API.post(`/api/v1/${ENTITY}/approbed/`, { ...data });

export const deleInteractiveProposalSignature = (offerId) =>
    API.delete(`/api/v1/${ENTITY}/signature/${offerId}/`);

export const getBranding = (offerId) =>
    API.get(`/api/v1/${ENTITY}/branding/${offerId}/`);

export const getElectricBills = (offerId) =>
    API.get(`/api/v1/${ENTITY}/electricbill/${offerId}/`);

export const getInteractiveProposal = (offerId) =>
    API.get(`/api/v1/${ENTITY}/interactive/${offerId}/`);

export const getInteractiveProposalContent = (offerId) =>
    API.get(`/api/v1/${ENTITY}/proposal/${offerId}/`);

export const getInteractiveProposalSignature = (offerId) =>
    API.get(`/api/v1/${ENTITY}/signature/${offerId}/`);

export const getProposalAnalytics = (offerId) =>
    API.get(`/api/v1/${ENTITY}/analytics/${offerId}/`);

export const getSmarDocumentContent = (documentId) =>
    API.get(`/api/v1/${ENTITY}/document/${documentId}/`);

export const saveInteractiveProposalSignature = (offerId, data) =>
    API.patch(`/api/v1/${ENTITY}/signature/${offerId}/`, { ...data });
